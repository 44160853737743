import { useEffect, useState } from "react";
import styles from "../../styles/admin/UserSettings.module.css";
import { ReactComponent as DeactivateAccountIcon } from "../../imgs/deactivate-account-icon.svg";
import BaseButton from "../base/BaseButton";
import useUserContext from "../auth/useUserContext";
import { useNavigate } from "react-router-dom";
import useAuthContext from "../auth/useAuthContext";

import { ReactComponent as CheckIcon } from "../../imgs/check-light-icon.svg";
import { ReactComponent as CheckMarkIcon } from "../../imgs/checkmark-icon.svg";
import { ReactComponent as LoadingSpinner } from "../../imgs/loading.svg";

import { toast } from "react-toastify";

const formatTimestamp = (timestamp) => {
    if (!timestamp) return;
    const date = new Date(timestamp);
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
    };
    return date.toLocaleString({}, options);
};

export function Card(props) {
    const { title, body } = props;

    return (
        <div key={title} className={styles.card}>
            <div className={styles.card_title}>{title}</div>
            <div className={styles.card_body}>{body}</div>
        </div>
    );
}

function MembershipBody({ settingsData }) {
    const membershipType = settingsData.membershipType;
    const expirationDate = settingsData.expirationDate;
    const credits = settingsData.credits;

    return (
        <div>
            <div className={styles.card_section}>
                <div className={styles.card_row}>
                    <p className={styles.row_title}>Membership Type:</p>
                    <p className={styles.row_content}>{membershipType}</p>
                </div>
                <div className={styles.card_row}>
                    <p className={styles.row_title}>Expiration Date:</p>
                    <p className={styles.row_content}>{formatTimestamp(expirationDate)}</p>
                </div>
                <div className={styles.card_row}>
                    <p className={styles.row_title}>Report Cards Remaining:</p>
                    <p className={`${styles.row_content} ${credits === "Unlimited" && styles.gradient_text}`}>
                        {credits}
                    </p>
                </div>
                <div className={styles.card_row}>
                    <p className={styles.row_title}>Update Membership:</p>
                    <div className={styles.row_content_container}>
                        <ManageMembershipButton />
                    </div>
                </div>
            </div>
        </div>
    );
}

function ManageMembershipButton() {
    const navigate = useNavigate();
    const handleNavigateToPricing = () => {
        navigate("/pricing");
    };

    return (
        <BaseButton className={styles.settings_action_button} onClick={handleNavigateToPricing}>
            Manage
        </BaseButton>
    );
}

function DeactivateAccountButton({ toggleDeactivationModal }) {
    return (
        <BaseButton className={styles.deactivate_account_button} onClick={() => toggleDeactivationModal(true)}>
            Deactivate
        </BaseButton>
    );
}

function UpdateLoginMethodButton({ toggleUpdateLoginMethodModal }) {
    return (
        <BaseButton className={styles.settings_action_button} onClick={() => toggleUpdateLoginMethodModal(true)}>
            Update
        </BaseButton>
    );
}

function AccountBody({ settingsData, toggleDeactivationModal, toggleUpdateLoginMethodModal }) {
    const accountType = settingsData.accountType;
    const loginMethod = settingsData.loginMethod;
    return (
        <div className={styles.card_section}>
            <div className={styles.card_row}>
                <p className={styles.row_title}>Account Type:</p>
                <p className={styles.row_content}>{accountType}</p>
            </div>
            <div className={styles.card_row}>
                <p className={styles.row_title}>Login Method:</p>
                <p className={styles.row_content}>{loginMethod}</p>
            </div>
            <div className={styles.card_row}>
                <p className={styles.row_title}>Update Login Method:</p>
                <div className={styles.row_content_container}>
                    <UpdateLoginMethodButton toggleUpdateLoginMethodModal={toggleUpdateLoginMethodModal} />
                </div>
            </div>
            <div className={styles.card_row}>
                <p className={styles.row_title}>Deactivate Account:</p>
                <div className={styles.row_content_container}>
                    <DeactivateAccountButton toggleDeactivationModal={toggleDeactivationModal} />
                </div>
            </div>
        </div>
    );
}

function ConsultantHelpBanner() {
    return (
        <div className={styles.banner}>
            If you need help, please reach out to{" "}
            <a className={styles.banner_mail_link} href="mailto:support@athenaco.ai">
                support@athenaco.ai
            </a>
        </div>
    );
}

function DeactivationWarningModal({ toggleModal, handleDeactivateAccount }) {
    return (
        <div className={styles.modal_background}>
            <div className={styles.modal_body}>
                <DeactivateAccountIcon className={styles.modal_warning_icon} />
                <h1 className={styles.modal_title}>Deactivate Account</h1>
                <p className={styles.modal_text}>
                    Are you sure you want to deactivate your account? This action cannot be undone.
                </p>
                <div className={styles.modal_button_options}>
                    <BaseButton
                        className={`${styles.modal_button} ${styles.modal_cancel_button}`}
                        onClick={() => toggleModal(false)}
                    >
                        Cancel
                    </BaseButton>
                    <BaseButton
                        className={`${styles.modal_button} ${styles.modal_deactivate_button}`}
                        onClick={handleDeactivateAccount}
                    >
                        Deactivate
                    </BaseButton>
                </div>
            </div>
        </div>
    );
}

function UpdateLoginMethodModal({ settingsData, toggleModal }) {
    const { updateLoginToEmail } = useAuthContext();
    const [updateResponse, setUpdateResponse] = useState(null);
    const doesUserSignInWithGoogle = settingsData.loginMethod === "Google";
    const email = settingsData.email;

    const handleUpdateLoginMethod = async () => {
        const formData = {
            email: email,
            is_login_method_update: true,
        };
        const response = await updateLoginToEmail(formData);
        setUpdateResponse(response);
    };

    const modalBody = doesUserSignInWithGoogle ? (
        <>
            <h1 className={styles.modal_title}>Update Login Method</h1>
            <p className={styles.modal_text}>
                You're currently using Google to sign in. If you'd like to sign in with your email,
                <strong>({email})</strong>, and a password, please click the button below.
            </p>
            {updateResponse && <p className={styles.modal_notice}>{updateResponse}</p>}
            <div className={styles.modal_button_options}>
                <BaseButton className={styles.modal_button} onClick={handleUpdateLoginMethod}>
                    Update
                </BaseButton>
                <BaseButton
                    className={`${styles.modal_button} ${styles.modal_cancel_button}`}
                    onClick={() => toggleModal(false)}
                >
                    Cancel
                </BaseButton>
            </div>
        </>
    ) : (
        <>
            <h1 className={styles.modal_title}>Update Login Method</h1>
            <p className={styles.modal_text}>
                You're currently using your email, <strong>({email})</strong>, and a password to sign in. If you would
                like to sign in via Google, please reach out to{" "}
                <a className={styles.modal_mail_link} href="mailto:support@athenaco.ai">
                    support@athenaco.ai
                </a>
            </p>
            <div className={styles.modal_button_options}>
                <BaseButton
                    className={`${styles.modal_button} ${styles.modal_cancel_button}`}
                    onClick={() => toggleModal(false)}
                >
                    Cancel
                </BaseButton>
            </div>
        </>
    );

    return (
        <div className={styles.modal_background}>
            <div className={styles.modal_body}>{modalBody}</div>
        </div>
    );
}

function ReferralProgressBar(props) {
    const { referralCount, completeReferralCount, isReferralCountLoading } = props;
    const REFERRAL_GOAL = 5;
    const referralTextEmoji =
        referralCount === REFERRAL_GOAL ? "🎉" : referralCount === 0 ? "😭" : referralCount > REFERRAL_GOAL ? "😳" : "";
    const referralText =
        typeof referralCount === "number"
            ? `${referralCount} friend${referralCount === 1 ? "" : "s"} signed up ${referralTextEmoji}`
            : "";
    const completeReferralText =
        typeof completeReferralCount === "number"
            ? `${completeReferralCount} referral${completeReferralCount === 1 ? "" : "s"} who used the UC model`
            : "";

    if (isReferralCountLoading) {
        return (
            <div className={styles.card_loading}>
                <LoadingSpinner className={styles.loading_spinner} />
            </div>
        );
    }

    const hasCompletedReferral = completeReferralCount >= REFERRAL_GOAL;

    return (
        <div className={styles.progress_bar_container}>
            {hasCompletedReferral && (
                <div className={styles.progress_bar_row}>
                    <div className={styles.referral_success_text}>
                        <CheckMarkIcon width={20} height={20} />
                        You're all set!
                    </div>
                </div>
            )}
            <div className={styles.progress_bar_row}>
                <progress className={styles.progress_bar} value={referralCount} max={REFERRAL_GOAL} />
                <span>{referralText}</span>
            </div>
            <div className={styles.progress_bar_row}>
                <progress className={styles.progress_bar} value={completeReferralCount} max={REFERRAL_GOAL} />
                <span>{completeReferralText}</span>
            </div>
            <div className={styles.progress_bar_row}>
                <span>{"🦉".repeat(referralCount)}</span>
            </div>
        </div>
    );
}

function ReferralBody() {
    const [code, setCode] = useState("");
    const [referralLink, setReferralLink] = useState("");
    const [referralCount, setReferralCount] = useState(0);
    const [completeReferralCount, setCompleteReferralCount] = useState(0);
    const [isCodeLoading, setIsCodeLoading] = useState(true);
    const [isReferralCountLoading, setIsReferralCountLoading] = useState(true);
    const [isCopyClicked, setIsCopyClicked] = useState(false);
    const { handleCreateReferralCode, handleFetchReferralCode, handleFetchReferralCodeUsage } = useAuthContext();

    useEffect(() => {
        handleFetchReferralCode()
            .then((response) => {
                const referralCode = response.data.referral_code;
                setCode(referralCode);
            })
            .catch((error) => toast.error(error?.response?.data?.error))
            .finally(() => setIsCodeLoading(false));
    }, [handleFetchReferralCode]);

    useEffect(() => {
        if (!code) return;

        const fetchReferralCounts = async () => {
            try {
                const [referralResponse, completeReferralResponse] = await Promise.all([
                    handleFetchReferralCodeUsage(code),
                    handleFetchReferralCodeUsage(code, "UC_NOV_2024_PROMOTION"),
                ]);

                setReferralCount(referralResponse.data.count);
                setCompleteReferralCount(completeReferralResponse.data.count);

                setIsReferralCountLoading(false);
            } catch (error) {
                toast.error(error?.response?.data?.error);
            }
        };

        fetchReferralCounts();
    }, [code, handleFetchReferralCodeUsage]);

    useEffect(() => {
        setReferralLink(`https://athenaco.ai/?referral_source=${code}`);
    }, [code]);

    // Setting free boba referral goal to 5 for now
    const generateLink = () => {
        if (isCodeLoading) return;

        setIsCodeLoading(true);
        handleCreateReferralCode({})
            .then((response) => setCode(response.data.referral_code))
            .catch((error) => toast.error(error?.response?.data?.error))
            .finally(() => setIsCodeLoading(false));
    };

    const copyCode = () => {
        setIsCopyClicked(true);
        navigator.clipboard.writeText(referralLink);
        setTimeout(() => {
            setIsCopyClicked(false);
        }, 2000);
    };

    if (isCodeLoading) {
        return (
            <div className={styles.card_loading}>
                <LoadingSpinner className={styles.loading_spinner} />
            </div>
        );
    }

    return (
        <div>
            <div className={styles.card_section}>
                <p className={styles.card_description}>
                    Use our University of California (UC) model and refer 5 friends who also use our UC model to earn a
                    chance to win{" "}
                    <a
                        href={"https://athenaco.ai/uc-promo"}
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        free boba for your entire freshman year
                    </a>
                    ! Plus, if you are one of the first 100 to reach 5 referrals, you will get 1 free boba on us! Share
                    your referral link below to get started.
                </p>
                <div className={styles.card_row}>
                    <p className={styles.row_title}>Referral Link:</p>
                    <p className={styles.row_content}>{code && referralLink}</p>
                    <div className={styles.row_content_container}>
                        {code ? (
                            <BaseButton
                                className={`${styles.settings_action_button} ${
                                    isCodeLoading && styles.disabled_button
                                }`}
                                onClick={copyCode}
                            >
                                {isCopyClicked ? <CheckIcon className={styles.check_icon} /> : "Copy Link"}
                            </BaseButton>
                        ) : (
                            <BaseButton
                                className={`${styles.settings_action_button} ${
                                    isCodeLoading && styles.disabled_button
                                }`}
                                onClick={generateLink}
                                disabled={isCodeLoading}
                            >
                                Generate Link
                            </BaseButton>
                        )}
                    </div>
                </div>
                <div className={styles.card_row}>
                    <p className={styles.row_title}>Referrals:</p>
                    {code ? (
                        <ReferralProgressBar
                            referralCount={referralCount}
                            completeReferralCount={completeReferralCount}
                            isReferralCountLoading={isReferralCountLoading}
                        />
                    ) : (
                        <p className={styles.row_content}>Generate link to get started</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default function UserSettings() {
    const [settingsData, setSettingsData] = useState({});
    const [showDeactivationWarningModal, setShowDeactivationWarningModal] = useState(false);
    const [showUpdateLoginMethodModal, setShowUpdateLoginMethodModal] = useState(false);
    const { userProfile, isIEC } = useUserContext();

    const toggleDeactivationModal = (isOpen) => {
        setShowDeactivationWarningModal(isOpen);
    };

    const handleDeactivateAccount = () => {
        alert("Please reach out to support@athenaco.ai to deactivate your account.");
        setShowDeactivationWarningModal(false);
    };

    const toggleUpdateLoginMethodModal = (isOpen) => {
        setShowUpdateLoginMethodModal(isOpen);
    };

    const formatCredits = (credits) => {
        const number = parseInt(credits);
        if (isNaN(number)) return credits;
        return number.toLocaleString();
    };

    useEffect(() => {
        const membershipPlan =
            userProfile.membership_plan?.[0].toUpperCase() + userProfile.membership_plan?.slice(1).toLowerCase() || "";
        const creditsMessage =
            membershipPlan === "Lite" || membershipPlan === "Pro" ? "Unlimited" : formatCredits(userProfile.credit);
        const loginMethod = userProfile.google_user_id
            ? "Google"
            : userProfile.clever_user_id
            ? "Clever"
            : "Email/Password";
        const formattedSettingsData = {
            email: userProfile.email,
            membershipType: membershipPlan,
            expirationDate: userProfile.membership_end_date,
            loginMethod: loginMethod,
            credits: creditsMessage,
            accountType: userProfile.account_type,
        };
        setSettingsData(formattedSettingsData);
    }, [userProfile]);

    if (isIEC) {
        return (
            <div className={styles.empty_page}>
                This page is not available for IECs. If you need help, please reach out to{" "}
                <a className={styles.mail_link} href="mailto:support@athenaco.ai">
                    support@athenaco.ai
                </a>
            </div>
        );
    }

    return (
        <div className={styles.user_settings_container}>
            <div className={styles.user_settings_header}>
                <h1 className={styles.title}>Settings</h1>
            </div>
            <div className={styles.user_settings}>
                <Card title={"Referral"} body={<ReferralBody />} />
                <Card title={"Membership"} body={<MembershipBody settingsData={settingsData} />} />
                <Card
                    title={"Account"}
                    body={
                        <AccountBody
                            settingsData={settingsData}
                            toggleDeactivationModal={toggleDeactivationModal}
                            toggleUpdateLoginMethodModal={toggleUpdateLoginMethodModal}
                        />
                    }
                />
                <ConsultantHelpBanner />
            </div>
            {showDeactivationWarningModal && (
                <DeactivationWarningModal
                    toggleModal={toggleDeactivationModal}
                    handleDeactivateAccount={handleDeactivateAccount}
                />
            )}
            {showUpdateLoginMethodModal && (
                <UpdateLoginMethodModal toggleModal={toggleUpdateLoginMethodModal} settingsData={settingsData} />
            )}
        </div>
    );
}
