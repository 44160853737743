import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";

export const STATIC_ATHENA_NAMESPACE_UUID =
    "e4c8ed73-0adb-44c5-8936-edd2a97a17d7";

export const CUSTOM_PROMPT_TITLE_MAX_LENGTH_CHARS = 300;

// Avoid potential abuse of people putting a bunch of tokens in and racking up OpenAI expenses.
export const ESSAY_MAX_LENGTH_CHARS = 4500; // 650 words = ~3055 characters
export const PROMPT_MAX_LENGTH_CHARS = 1500; // 150 words (guesstimate) = ~705 characters
export const DEFAULT_TEXTAREA_MAX_LENGTH_CHARS = 1500; // just a default value for textareas

export const DEFAULT_ERROR_MESSAGE =
    "Something went wrong on our end. Please try again later.";

export function FormatModelResponse(modelResponse) {
    if (modelResponse === null || modelResponse === undefined) {
        return "";
    }
    return modelResponse
        .replace(
            /: Excellent/g,
            `: <span class="${styles["rating-excellent"]}">Excellent</span>`
        )
        .replace(
            /: Okay/g,
            `: <span class="${styles["rating-okay"]}">Okay</span>`
        )
        .replace(
            /: Needs Work/g,
            `: <span class="${styles["rating-needs-work"]}">Needs Work</span>`
        )
        .replace(/\n/g, "\n\n");
}

export function ExtractErrorMessage(error) {
    return error.response && error.response.data && error?.response?.data?.error
        ? error?.response?.data?.error
        : DEFAULT_ERROR_MESSAGE;
}
