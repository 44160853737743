import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";

export default function NextSteps(props) {
    return (
        <div className={styles.next_steps_container}>
            <div className={styles.tip}>
                <p className={styles.suggestion}>
                    Reflect on important emotional moments: Identify moments in your journey that deeply impacted you
                    emotionally, like a moment of discovery of an industry flaw or an interaction with your mother that
                    felt deeply significant.
                </p>
            </div>
            <div className={styles.tip}>
                <p className={styles.suggestion}>
                    Include your first name somewhere in your essay to re-humanize you: Mentioning her first name in a
                    reflective moment, perhaps when discussing her younger self, could draw the reader in further. This
                    would help humanize the narrative and create a more personable connection with the audience as they
                    follow her journey.
                </p>
            </div>
            <div className={styles.tip}>
                <p className={styles.quoted_text}>
                    "Perhaps I am also drawn to makeup because as I peel back the layers, I am still wholly me. I am
                    still the young girl staring wide-eyed at her reflection, earnestly questioning in an attempt to
                    learn more about the world. Most importantly, I still carry an unflagging vigor to coalesce
                    creativity and activism into palpable change, one brushstroke at a time."
                </p>
                <p className={styles.suggestion}>
                    To strengthen the ending, the applicant could re-emphasize a pivotal moment or insight from the
                    essay while discussing where she sees herself and her activism going in the future. Providing a
                    lesson learned or a call to action would create a more impactful closing.
                </p>
            </div>
        </div>
    );
}
