import { ReactComponent as LoadingSpinner } from "../../imgs/loading.svg";
import styles from "../../styles/auth/AppRedirectPage.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

/*
 * A component that will be used to redirect the user to the correct page depending on a url parameter
 * that can either be "iec" or "app". If it is "iec", redirect to iec.athenaco.ai. If it is "app", redirect
 * to app.athenaco.ai. If it is neither, redirect to the home page.
 */
export default function AppRedirectPage(props) {
    const [searchParams] = useSearchParams();
    const destination = searchParams.get("to");
    const [redirectDestination, setRedirectDestination] = useState("");
    const [redirectUrls, setRedirectionUrls] = useState({
        iec: "https://iec.athenaco.ai",
        app: "https://app.athenaco.ai",
    });
    const REDIRECT_TIMEOUT_MS = 3000;
    const navigate = useNavigate();

    useEffect(() => {
        if (process.env.REACT_APP_IS_LOCAL_ENVIRONMENT) {
            setRedirectionUrls({
                iec: "http://localhost:5173",
                app: "http://localhost:3000",
            });
        }
    }, []);

    useEffect(() => {
        if (destination === "iec") {
            setRedirectDestination(redirectUrls.iec);
        } else if (destination === "app") {
            setRedirectDestination(redirectUrls.app);
        } else {
            setRedirectDestination("");
        }
    }, [destination, redirectUrls]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (redirectDestination === "") {
                navigate("/");
                return;
            }
            window.location.href = redirectDestination;
        }, REDIRECT_TIMEOUT_MS);

        return () => clearTimeout(timer);
    }, [redirectDestination, navigate]);

    const destinations = {
        iec: "Athena for IECs",
        app: "Athena",
    };
    const destinationDesc = destinations[destination] || "the home page";

    return (
        <div className={styles.page_container}>
            <LoadingSpinner className={styles.loading_spinner} />
            <p className={styles.redirect_text}>Redirecting you to {destinationDesc}</p>
        </div>
    );
}
