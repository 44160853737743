import { useEffect, useState } from "react";
import styles from "../../styles/applicant/ApplicantBackground.module.css";
import { toast } from "react-toastify";

import { fetchApplicantBackgroundData, saveApplicantBackgroundData } from "../../api/apiCalls";

const allQuestions = {
    personal: [
        "How would your best friend describe you?",
        "What are a few ways you have changed since the start of high school?",
        "What makes you quirky / stand out?",
        "How has your background (cultural or otherwise) shaped who you are?",
    ],
    activities: [
        "List out what extracurricular activities you are in, any positions you have held, and why you enjoy them.",
        "What are your most impressive accomplishments?",
        "What cool hobbies do you have?",
    ],
    academics: ["What do you want to study in college, and why?"],
    futureImpact: [
        "If you could change one thing about the world, what would it be and why?",
        "What are your goals and dreams for your future in life?",
    ],
};

function BackgroundQuestions(props) {
    const { category, backgroundData, handleBackgroundDataChange, setHasRecentChanges } = props;
    const handleChange = (event, prompt, question_id) => {
        const { value } = event.target;
        handleBackgroundDataChange(question_id, prompt, value);
        setHasRecentChanges(true);
    };

    const questions = allQuestions[category].map((question, index) => {
        const question_id = category + "_" + index;
        return (
            <div key={question_id} className={styles.question_container}>
                <div className={styles.question}>{question}</div>
                <textarea
                    className={styles.textarea}
                    onChange={(event) => handleChange(event, question, question_id)}
                    value={backgroundData[question_id]?.response || ""}
                    maxLength={
                        question_id === "activities_0" ? 2200 : 800
                    } /* Extracurricular Question Needs More Chars */
                />
            </div>
        );
    });
    return questions;
}

export default function ApplicantBackground() {
    const [activeTab, setActiveTab] = useState("personal");
    // backgroundData will only include "response" while unsavedData will include prompt and response
    const [backgroundData, setBackgroundData] = useState({});
    const [unsavedData, setUnsavedData] = useState({});
    const [hasRecentChanges, setHasRecentChanges] = useState(false);

    useEffect(() => {
        fetchApplicantBackgroundData()
            .then((response) => {
                setBackgroundData(response.data.background.responses);
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    }, []);

    const handleSwitchTab = (tab) => {
        setActiveTab(tab);
    };

    // Automatically save new user info every two minutes
    useEffect(() => {
        if (!hasRecentChanges) return;

        const interval = setInterval(() => {
            saveData();
        }, 1000);

        return () => clearInterval(interval);
    }, [hasRecentChanges, backgroundData]);

    const saveData = () => {
        const formattedData = { responses: unsavedData };
        saveApplicantBackgroundData(formattedData);
        setHasRecentChanges(false);
        setUnsavedData({});
    };

    const handleBackgroundDataChange = (question_id, prompt, value) => {
        setUnsavedData({ ...unsavedData, [question_id]: { prompt: prompt, response: value } });
        setBackgroundData({ ...backgroundData, [question_id]: { response: value } });
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.background_header}>
                <h1 className={styles.title}>10 Quick Questions</h1>
                <div className={styles.description}>
                    <p>
                        <b>If you want to skip this part, you can!</b> But the more information you give us here, the
                        more personalized Athena's feedback will be. You can always come back and add more so don't get
                        too caught up.
                    </p>
                    <p>
                        Be lazy. Jot down your thoughts in bullet points and use simple language — imagine you're just
                        talking to a friend.{" "}
                        <a
                            href="https://docs.google.com/document/d/1DpB7CFVjqJ8pya1I0B127m6IVWM1hYG3GhFQTmlw4po/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Here's an example of how to fill it out.
                        </a>
                    </p>
                    <p>We recommend jotting down thoughts using bullet points!</p>
                </div>
                <div className={styles.tabs_container}>
                    <div
                        className={`${styles.tab} ${activeTab === "personal" && styles.active_tab}`}
                        onClick={() => handleSwitchTab("personal")}
                    >
                        Personal
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === "activities" && styles.active_tab}`}
                        onClick={() => handleSwitchTab("activities")}
                    >
                        Activities
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === "academics" && styles.active_tab}`}
                        onClick={() => handleSwitchTab("academics")}
                    >
                        Academics
                    </div>
                    <div
                        className={`${styles.tab} ${activeTab === "futureImpact" && styles.active_tab}`}
                        onClick={() => handleSwitchTab("futureImpact")}
                    >
                        Future Impact
                    </div>
                </div>
            </div>
            <BackgroundQuestions
                category={activeTab}
                backgroundData={backgroundData}
                handleBackgroundDataChange={handleBackgroundDataChange}
                setHasRecentChanges={setHasRecentChanges}
            />
        </div>
    );
}
