import { useEffect, useState, useRef } from "react";
import useUserContext from "./useUserContext";
import { useNavigate } from "react-router-dom";

import SignupPage from "./SignupPage";
import LoginPage from "./LoginPage";
import PricingPage from "../pricing/PricingPage";

import authStyles from "../../styles/auth/AuthPage.module.css";
import styles from "../../styles/auth/BuyNowPage.module.css";

import Logo from "../../imgs/logo.png";

export default function BuyNowPage(props) {
    const authWidgetStepNumber = 1;
    const membershipSelectionStepNumber = 2;
    const paymentStepNumber = 3;

    const [showSignupWidget, setShowSignupWidget] = useState(true);
    const [stepNumber, setStepNumber] = useState(authWidgetStepNumber);
    const { isLoggedIn } = useUserContext();
    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    useEffect(() => {
        if (isLoggedIn) {
            setStepNumber(membershipSelectionStepNumber);
        }
    }, [isLoggedIn]);

    const renderBody = () => {
        switch (stepNumber) {
            case authWidgetStepNumber:
                if (showSignupWidget) {
                    return (
                        <div className={authStyles.new_background_buy_now_flow}>
                            <div className={authStyles.auth_container}>
                                {
                                    <SignupPage
                                        navigateOnSignupCompletion={"/buy-now"}
                                        onLoginClick={() => setShowSignupWidget(false)}
                                    />
                                }
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className={authStyles.new_background_buy_now_flow}>
                            <div className={authStyles.auth_container}>
                                {
                                    <LoginPage
                                        navigateOnLoginCompletion={"/buy-now"}
                                        onSignupClick={() => setShowSignupWidget(true)}
                                    />
                                }
                            </div>
                        </div>
                    );
                }
            case membershipSelectionStepNumber:
                return <PricingPage shouldOpenInNewTab={false} />;
            default:
                return <PricingPage shouldOpenInNewTab={false} />;
        }
    };

    return (
        <div>
            <div className={styles.topbar}>
                <img className={styles.athena_logo} src={Logo} alt="Logo" onClick={() => navigate("/")} />
            </div>
            <div className={styles.topbar}>
                <div />
                <div className={styles.menu}>
                    {stepInMenu(1, "Create Account", stepNumber)}
                    {stepInMenu(2, "Select Plan", stepNumber)}
                    {stepInMenu(3, "Pay", stepNumber)}
                </div>
                <div /> {/* Empty div to push the menu to be spaced out evenly */}
            </div>
            {renderBody()}
        </div>
    );
}

function stepInMenu(menuNumber, stepText, currentNumber) {
    const isFinished = currentNumber > menuNumber;
    const isCurrent = currentNumber === menuNumber;

    return (
        <div className={styles.step}>
            <div
                className={`${styles.step_number} ${
                    isFinished || isCurrent ? styles.step_number_finished_or_current : styles.step_number_unreached
                }`}
            >
                {isFinished ? "✔" : menuNumber}
            </div>
            <div
                className={`${styles.step_text} ${
                    isFinished || isCurrent ? styles.step_text_finished_or_current : styles.step_text_unreached
                }`}
            >
                {stepText}
            </div>
        </div>
    );
}
