import { useEffect, useState } from "react";
import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";
import useApplicationContext from "../useApplicationContext";
import useUserContext from "../../auth/useUserContext";
import LoadingSection from "../../main/subcomponents/LoadingSection";
import BaseButton from "../../base/BaseButton";
import blackAthenaV2 from "../../../imgs/black-athena-v2.png";
import { deleteAllChatMessages, fetchChatHistory } from "../../../api/apiCalls";
import { FormatModelResponse } from "../../main/utils/utils";

import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function MessageBubble(props) {
    const { userProfile } = useUserContext();
    const { message, sender, messageType } = props;
    const isUserMessage = sender === "user";
    const userProfileImg = userProfile.profile_image || blackAthenaV2;

    return (
        <div className={`${isUserMessage ? styles.user_message_bubble_container : styles.message_bubble_container}`}>
            <div className={styles.message_bubble}>
                <div className={`${isUserMessage ? styles.user_message_bubble_header : styles.message_bubble_header}`}>
                    {/* If we only have user messages, we don't need the confusing order 1, 2, 3 css for these messages */}
                    <img
                        className={`${isUserMessage ? styles.user_message_bubble_icon : styles.message_bubble_icon}`}
                        src={isUserMessage ? userProfileImg : blackAthenaV2}
                        alt="message sender"
                    />
                    <span
                        className={`${
                            isUserMessage ? styles.user_message_bubble_sender : styles.message_bubble_sender
                        }`}
                    >
                        {isUserMessage ? "You" : "Athena"}
                    </span>
                    <span
                        className={`${
                            isUserMessage ? styles.user_message_bubble_message_type : styles.message_bubble_message_type
                        }`}
                    >
                        {messageType}
                    </span>
                </div>
                <div className={styles.message_bubble_message}>
                    <ReactMarkdown>{FormatModelResponse(message)}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

function EssayGuidanceFooter(props) {
    const { essayId, initialMessage, setMessages } = useApplicationContext();
    const navigate = useNavigate();

    const handleClearHistoryClick = () => {
        deleteAllChatMessages(essayId)
            .then((_) => {
                return fetchChatHistory(essayId)
                    .then((response) => {
                        const rawChatHistory = response.data.chat_history;
                        const formattedChatHistory = rawChatHistory.map((chat) => {
                            return {
                                id: chat.id,
                                message: chat.message,
                                sender: chat.sender,
                                messageType: chat.message_type,
                            };
                        });
                        if (!formattedChatHistory || formattedChatHistory.length === 0) {
                            setMessages([initialMessage]);
                        } else {
                            setMessages([...formattedChatHistory]);
                        }
                    })
                    .catch((error) => {
                        toast.error(`Error fetching chat history: ${error?.response?.data?.error}`);
                        setMessages([initialMessage]);
                    });
            })
            .catch((error) => {
                toast.error(`Error deleting message: ${error?.response?.data?.error}`);
            });
    };

    return (
        <div className={styles.athena_chat_footer}>
            <p className={styles.athena_chat_notice}>
                Complete your{" "}
                <span className={styles.athena_chat_background_link} onClick={() => navigate("/background")}>
                    background
                </span>{" "}
                for personalized feedback!
            </p>
            <BaseButton className={styles.clear_history_button} onClick={handleClearHistoryClick}>
                Clear History
            </BaseButton>
        </div>
    );
}

export default function EssayGuidance(props) {
    const { messages, isBrainstormLoading, isProgressCheckLoading } = useApplicationContext();
    const [formattedMessageList, setFormattedMessageList] = useState([]);

    useEffect(() => {
        const formattedMessages = messages.map((message, index) => {
            const formattedMessageType = message.messageType
                ?.split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
            return (
                <MessageBubble
                    key={message.id + index}
                    message={message.message}
                    sender={message.sender}
                    messageType={formattedMessageType}
                />
            );
        });
        setFormattedMessageList(formattedMessages);
    }, [messages, setFormattedMessageList]);

    return (
        <div className={styles.athena_chat}>
            <div className={styles.athena_chat_container}>{formattedMessageList}</div>
            {(isBrainstormLoading || isProgressCheckLoading) && (
                <div className={styles.loading_image}>
                    <LoadingSection shouldShowText={false} />
                </div>
            )}
            <EssayGuidanceFooter />
        </div>
    );
}
