import { forwardRef, useState } from "react";
import { ReactComponent as LoadingSpinner } from "../../imgs/loading.svg";
import styles from "../../styles/base/BaseButton.module.css";

import { toast } from "react-toastify";

const BaseButton = forwardRef(function BaseButton({ ...props }, ref) {
    const { className = "", onClick = () => {}, type, disabled = false, style = {}, showLoading = true } = props;

    const [isActionLoading, setIsActionLoading] = useState(false);
    const handleOnClick = async () => {
        // For now, we default all buttons to allow only one click at a time
        if (isActionLoading) return;

        setIsActionLoading(true);
        Promise.resolve(onClick())
            .then(() => {
                // Handle success if necessary
            })
            .catch((error) => {
                // Handle error if necessary
                toast.error("An error occurred. Please try again later.");
            })
            .finally(() => {
                setIsActionLoading(false);
            });
    };

    return (
        <button
            className={`${className} ${styles.base_button}`}
            onClick={handleOnClick}
            type={type}
            disabled={disabled}
            style={style}
            ref={ref}
        >
            {props.children}
            {showLoading && isActionLoading && <LoadingSpinner className={styles.loading_spinner} />}
        </button>
    );
});

export default BaseButton;
