import {Card} from "./AdminDashboard";
import form_styles from "../../styles/forms/Forms.module.css";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import BaseButton from "../base/BaseButton";
import Dropdown from "../base/Dropdown";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
    createConsultantOrganization,
    createSpecialUserSetup,
    fetchAllConsultantOrganizations,
} from "../../api/apiCalls";


function CreateConsultantOrganization() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isAdminSeesAllStudents, setIsAdminSeesAllStudents] = useState(null);
    const [licenseEndDate, setLicenseEndDate] = useState(new Date());

    const dropdownRef = useRef(null);
    const isAdminSeesAllStudentsOptions = [
        { name: "Yes", value: true },
        { name: "No", value: false },
    ];
    const isAdminSeesAllStudentsDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={isAdminSeesAllStudentsOptions}
            dropdownRef={dropdownRef}
            selectedItem={isAdminSeesAllStudentsOptions.find((item) => item.value === isAdminSeesAllStudents)}
            setSelectedItem={(item) => setIsAdminSeesAllStudents(item.value)}
            placeholder={"If you're unsure, default to yes."}
        />
    );

    const shouldDisableButton = isAdminSeesAllStudents === null || licenseEndDate === null;

    const handleAddOrganizationClick = (event) => {
        event.preventDefault();

        const formData = {
            name: event.target.organizationName.value,
            num_lite_licenses: event.target.numLiteLicenses.value,
            num_pro_licenses: event.target.numProLicenses.value,
            is_admin_sees_all_students: isAdminSeesAllStudents,
            end_date: licenseEndDate.toISOString().split('T')[0],
        }
        
        createConsultantOrganization(formData)
            .then((response) => {
                toast.success("Organization created successfully!");
            })
            .catch((error) => {
                toast.error("Error creating organization. Look at the backend logs for more information.")
            });
        
    }

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleAddOrganizationClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="organizationName">Organization Name</label>
                    <input id="organizationName" type="text" placeholder="Riley Baker" required />
                </div>
                <div className={form_styles.side_by_side}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="numLiteLicenses"># of Athena Lite Licenses</label>
                        <input id="numLiteLicenses" type="number" placeholder="10" required />
                    </div>
                    <div className={form_styles.form_section}>
                        <label htmlFor="numProLicenses"># of Athena Pro Licenses</label>
                        <input id="numProLicenses" type="number" placeholder="10" required />
                    </div>
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectLicenseEndDate">License End Date</label>
                    <DatePicker className={form_styles.mimic_input} selected={licenseEndDate} onChange={(date) => setLicenseEndDate(date)} />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectIsAdminSeesAllStudents">Should all IECs be able to view all students at the firm?</label>
                    {isAdminSeesAllStudentsDropdown}
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${
                        shouldDisableButton && form_styles.disabled_form_button
                    }`}
                    disabled={shouldDisableButton}
                >
                    Add Organization
                </BaseButton>
            </form>
        </div>
    )
}

function AddIndividualToExistingConsultantOrganization() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [consultantOrganizations, setConsultantOrganizations] = useState([]);
    const [selectedConsultantOrganization, setSelectedConsultantOrganization] = useState(null);

    useEffect(() => {
        fetchAllConsultantOrganizations()
          .then(response => {
            setConsultantOrganizations(response?.data.organizations);
          })
          .catch(error => {
            toast.error("Error fetching all consultant organizations. See backend logs for more information.");
          });
      }, []);

    const dropdownRef = useRef(null);
    const allConsultantOrganizationsDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={consultantOrganizations}
            dropdownRef={dropdownRef}
            selectedItem={selectedConsultantOrganization}
            setSelectedItem={setSelectedConsultantOrganization}
            placeholder={"Select the IEC firm to add this email address to."}
        />
    );

    const shouldDisableButton = selectedConsultantOrganization === null;

    const handleAddIndividualClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailAddress.value,
            setup_flow_type: "consultant",
            metadata: {
                "organization_id": selectedConsultantOrganization.id,
            },
        }
        
        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success("Individual added to IEC firm successfully!");
            })
            .catch((error) => {
                toast.error("Error adding consultant to organization. See backend logs for more information.")
            });
        
    }

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleAddIndividualClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailAddress">Email Address</label>
                    <input id="emailAddress" type="email" placeholder="first@iec.com" required />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectConsultantOrganization">Consultant Organization</label>
                    {allConsultantOrganizationsDropdown}
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${
                        shouldDisableButton && form_styles.disabled_form_button
                    }`}
                    disabled={shouldDisableButton}
                >
                    Add Individual to Organization
                </BaseButton>
            </form>
        </div>
    )
}

function SetAsHighSchoolCounselor() {

    const handleClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailAddress.value,
            setup_flow_type: "high_school_counselor",
            metadata: {},
        }
        
        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success("Email provisioned as high school counselor successfully!");
            })
            .catch((error) => {
                toast.error("Error provisioning high school counselor. See backend logs for more information.")
            });
        
    }

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailAddress">Email Address</label>
                    <input id="emailAddress" type="email" placeholder="first@iec.com" required />
                </div>
                <BaseButton
                    className={form_styles.form_button}
                >
                    Provision
                </BaseButton>
            </form>
        </div>
    )
}

function CreatePilot() {
    const [pilotStartDate, setPilotStartDate] = useState(new Date());
    const [pilotEndDate, setPilotEndDate] = useState(new Date());
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const dropdownRef = useRef(null);
    const membershipPlanOptions = [
        // { name: "Starter", value: "starter" },
        { name: "Lite", value: "lite" },
        { name: "Pro", value: "pro" },
    ];
    const membershipPlanDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={membershipPlanOptions}
            dropdownRef={dropdownRef}
            selectedItem={selectedPlan}
            setSelectedItem={setSelectedPlan}
            placeholder={"Select Membership Plan"}
        />
    );
    const shouldDisableButton = selectedPlan === null || pilotStartDate === null || pilotEndDate === null;

    const handleCreatePilotClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailSuffix.value,
            setup_flow_type: "pilot",
            metadata: {
                "membership_plan": selectedPlan.value,
                "start_date": pilotStartDate.toISOString().split('T')[0],
                "end_date": pilotEndDate.toISOString().split('T')[0],
            },
        }

        console.log(formData);
        
        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success(`Pilot created successfully for ${formData.email}!`);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Error creating pilot. Look at the backend logs for more information.")
            });
        
    }

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleCreatePilotClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailSuffix">Email Suffix</label>
                    <input id="emailSuffix" type="text" placeholder="@student.smusd.us" required />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectMembershipPlan">Membership Plan</label>
                    {membershipPlanDropdown}
                </div>
                <div className={form_styles.side_by_side}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="selectStartDate">Pilot Start Date</label>
                        <DatePicker className={form_styles.mimic_input} selected={pilotStartDate} onChange={(date) => setPilotStartDate(date)} />
                    </div>
                    <div className={form_styles.form_section}>
                        <label htmlFor="selectEndDate">Pilot End Date</label>
                        <DatePicker className={form_styles.mimic_input} selected={pilotEndDate} onChange={(date) => setPilotEndDate(date)} />
                    </div>
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${
                        shouldDisableButton && form_styles.disabled_form_button
                    }`}
                    disabled={shouldDisableButton}
                >
                    Create Pilot
                </BaseButton>
            </form>
        </div>
    )
}

export default function AdminB2BActions() {
    return (
        <div className={form_styles.form_page_container}>
            <div className={form_styles.page_description}>
            <h1 className={form_styles.title}>Admin B2B Actions</h1>
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Add New IEC Organization"} body={<CreateConsultantOrganization />}/>
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Add Individual to Existing IEC Organization"} body={<AddIndividualToExistingConsultantOrganization />}/>
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Create High School Pilot"} body={<CreatePilot />}/>
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Set as High School Counselor"} body={<SetAsHighSchoolCounselor />}/>
            </div>
        </div>
    )
}