import styles from "../../styles/auth/AuthPage.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";

import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./LoginPage";
import ResetPasswordPage from "./ResetPasswordPage";
import SignupPage from "./SignupPage";
import useUserContext from "./useUserContext";

export default function AuthPage(props) {
    const { isLoggedIn } = useUserContext();
    const navigate = useNavigate();
    const navigateRef = useRef(navigate);
    const [searchParams] = useSearchParams();
    const searchParamString = searchParams.toString();
    const { authView } = props;

    useEffect(() => {
        if (isLoggedIn && authView !== "reset-password") {
            navigateRef.current("/");
        }
    }, [isLoggedIn, authView]);

    useEffect(() => {
        const referralSource = searchParams.get("referral_source");
        if (referralSource) {
            sessionStorage.setItem("referral_source", referralSource);
        }
    }, [searchParams]);

    const renderBody = () => {
        switch (authView) {
            case "login":
                return (
                    <LoginPage
                        navigateOnLoginCompletion={"/"}
                        onSignupClick={() => {
                            navigate(`/signup?${searchParamString}`);
                        }}
                    />
                );
            case "signup":
                return (
                    <SignupPage
                        navigateOnSignupCompletion={"/account-setup"}
                        onLoginClick={() => {
                            navigate(`/login?${searchParamString}`);
                        }}
                    />
                );
            case "forgot-password":
                return <ForgotPasswordPage />;
            case "reset-password":
                return <ResetPasswordPage />;
            default:
                return (
                    <LoginPage
                        navigateOnLoginCompletion={"/"}
                        onSignupClick={() => {
                            navigate(`/signup?${searchParamString}`);
                        }}
                    />
                );
        }
    };
    return (
        <div className={styles.new_background}>
            <div className={styles.auth_container}>{renderBody()}</div>
        </div>
    );
}
