import { useEffect, useRef, useState } from "react";
import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";
import useUserContext from "../../auth/useUserContext";
import useApplicationContext from "../useApplicationContext";
import { LightweightResponseFeedback } from "../../main/subcomponents/ResponseFeedback";
import InterpretFeedbackHyperlink from "../../main/subcomponents/InterpretFeedbackHyperlink";
import GreenCheckMark from "../../main/subcomponents/GreenCheckMark";
import LoadingSection from "../../main/subcomponents/LoadingSection";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import ReactMarkdown from "react-markdown";

import ChevronDownIcon from "../../../imgs/chevron-down-icon.png";
import ChevronRightIcon from "../../../imgs/chevron-right-icon.png";
import ChevronUpIcon from "../../../imgs/chevron-up-icon.png";
import DoubleChevronRightIcon from "../../../imgs/double-chevron-right-icon.png";
import NoEssayFeedback from "../../../imgs/no-essay-feedback.png";
import BaseButton from "../../base/BaseButton";
import BlueZapIcon from "../../../imgs/blue-zap-icon.png";
import GradeIcon from "../../../imgs/grade-icon.png";
import GoogleDocComments from "./GoogleDocComments";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { doesAthenaProModelExist, getHistoryItem } from "../../../api/apiCalls";

import { FormatModelResponse } from "../../main/utils/utils";

function EssayFeedback(props) {
    const { errorMessageRef } = props;
    const { frontendActiveProfile } = useUserContext();

    const {
        applicationInfo,
        v2ModelResponseJSON,
        v2GradeErrorMessage,
        handleSubmitGradeRequest,
        isV2EssaySubmissionLoading,
        currentRequestId,
        editorStateStr,
    } = useApplicationContext();
    const navigate = useNavigate();

    const handleGradeClick = () => {
        try {
            handleSubmitGradeRequest(editorStateStr);
        } catch (error) {
            toast.error(error.message);
            return;
        }
    };

    const [doesProModelExist, setDoesProModelExist] = useState(false);

    useEffect(() => {
        doesAthenaProModelExist(applicationInfo.schoolCode)
            .then((response) => {
                setDoesProModelExist(response.data.does_pro_model_exist);
            })
            .catch((error) => {
                toast.error(
                    `Error checking for Athena Pro models: ${error?.response?.data?.error}`
                );
                setDoesProModelExist(false); // Default to false if there's an error
            });
    }, [applicationInfo.schoolCode]);

    const isProUser = frontendActiveProfile.membership_plan === "pro";

    return (
        <div className={styles.essay_feedback_container}>
            {v2ModelResponseJSON.main_feedback ||
            isV2EssaySubmissionLoading ||
            v2GradeErrorMessage ? (
                <div className={styles.essay_feedback}>
                    <div className={styles.essay_feedback_header}>
                        {doesProModelExist ? (
                            <div
                                className={styles.sidebar_pro_upsell_container}
                            >
                                {isProUser ? (
                                    <div className={styles.pro_user_notice}>
                                        <p>
                                            You're currently getting
                                            school-specific feedback for
                                        </p>
                                        <p>
                                            🎉
                                            <strong>
                                                {applicationInfo.schoolName}
                                            </strong>
                                            🎉
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <span
                                            className={
                                                styles.sidebar_pro_upsell
                                            }
                                        >
                                            Want to use our tailored model for{" "}
                                            <strong>
                                                {applicationInfo.schoolName}
                                            </strong>
                                            ?
                                        </span>
                                        <BaseButton
                                            className={styles.upgrade_button}
                                            onClick={() => navigate("/pricing")}
                                        >
                                            <img
                                                className={
                                                    styles.action_button_icon
                                                }
                                                src={BlueZapIcon}
                                                alt="upgrade icon"
                                            />
                                            <div
                                                className={
                                                    styles.action_button_text
                                                }
                                            >
                                                Upgrade to Pro
                                            </div>
                                        </BaseButton>
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div className={styles.sidebar_section_title}>
                            Athena's feedback
                        </div>
                        <div className={styles.sidebar_section_subtitle}>
                            {/* TODO (Eugene): Make this less jank */}
                            Graded using Athena's{" "}
                            {doesProModelExist && isProUser
                                ? "Pro"
                                : "Flagship"}{" "}
                            Model
                        </div>
                    </div>
                    <div className={styles.loading_image}>
                        {isV2EssaySubmissionLoading ? (
                            <LoadingSection shouldShowText={true} />
                        ) : (
                            v2ModelResponseJSON.main_feedback && (
                                <GreenCheckMark />
                            )
                        )}
                    </div>
                    {v2GradeErrorMessage && (
                        <div
                            ref={errorMessageRef}
                            className={styles.grade_error_message}
                        >
                            {v2GradeErrorMessage}
                        </div>
                    )}
                    {v2ModelResponseJSON.main_feedback && (
                        <>
                            <div className={styles.model_response_container}>
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                >
                                    {FormatModelResponse(
                                        v2ModelResponseJSON.grading_breakdown
                                    )}
                                </ReactMarkdown>
                            </div>
                            <div className={styles.divider} />
                            <GoogleDocComments />
                            <div className={styles.divider} />
                            <div className={styles.essay_feedback_content}>
                                <InDepthReview
                                    feedback={v2ModelResponseJSON.main_feedback}
                                />
                            </div>
                            <div className={styles.divider} />
                            <LightweightResponseFeedback
                                currentRequestId={currentRequestId}
                            />
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.no_essay_feedback}>
                    <img
                        src={NoEssayFeedback}
                        className={styles.no_essay_feedback_img}
                        alt="no feedback"
                    />
                    <h3 className={styles.no_essay_feedback_title}>
                        No feedback yet
                    </h3>
                    <p className={styles.no_essay_feedback_text}>
                        Submit your draft when you're ready to receive
                        personalized feedback. Ready to start?
                    </p>
                    <BaseButton
                        className={styles.grade_button}
                        onClick={handleGradeClick}
                    >
                        <img
                            className={styles.grade_icon}
                            src={GradeIcon}
                            alt="Grade Icon"
                        />
                        Grade
                    </BaseButton>
                </div>
            )}
        </div>
    );
}

function InDepthReview(props) {
    const [showInDepthReview, setShowInDepthReview] = useState(false);

    const displayText = showInDepthReview ? "Click to hide" : "Click to expand";

    const revealDiv = (
        <div
            className={styles.reveal_div}
            onClick={() => setShowInDepthReview(!showInDepthReview)}
        >
            <span>{displayText} </span>
            <img
                className={`${styles.chevron} ${
                    showInDepthReview && styles.rotated_chevron
                }`}
                src={ChevronDownIcon}
                alt="arrow"
            />
        </div>
    );

    return (
        <>
            <h1>In-Depth Review</h1>
            {revealDiv}
            <div className={styles.model_response_container}>
                <div
                    className={`${styles.in_depth_review_container} ${
                        showInDepthReview
                            ? styles.review_shown
                            : styles.review_hidden
                    }`}
                >
                    <ReactMarkdown>
                        {FormatModelResponse(props.feedback)}
                    </ReactMarkdown>
                </div>
            </div>
        </>
    );
}

function GradeDisclaimer(props) {
    return (
        <div className={styles.note_container}>
            <p className={styles.note}>
                Note: Essay grades are a heuristic and can fluctuate up and down
                slightly (e.g. from a B to a B+ or vice-versa) but generally not
                more than that. Think of this as how your essay roughly compares
                to other successful essays!
            </p>
            {/* For V2, we say A+/A are rare in comparison to V1's A/A- are rare */}
            <p className={styles.note}>
                Note: An A+ or even an A is meant to be rare. When our platform
                gives that grade out, it means it has satisfied all the core
                requirements that were present across thousands of essays that
                actually worked. When that grade comes through, you know you
                have an extremely strong chance of having close to a "perfect"
                essay.
            </p>
        </div>
    );
}

function PreviousSubmissionItem(props) {
    const { item, setSelectedItem, setShowPreviousSubmissionDisplay } = props;
    const handleClick = () => {
        setSelectedItem(item);
        setShowPreviousSubmissionDisplay(true);
    };

    return (
        <li
            className={styles.previous_submissions_list_item}
            onClick={handleClick}
        >
            <div className={styles.submission_info}>
                <span className={styles.submission_preview}>
                    {item.essayPreview}
                </span>
                <span className={styles.submission_date}>{item.date}</span>
            </div>
            <img
                className={styles.chevron}
                src={ChevronRightIcon}
                alt="arrow"
            />
        </li>
    );
}

function V2PreviousSubmissionDisplay(props) {
    const { selectedItem, setShowPreviousSubmissionDisplay } = props;
    const [submission, setSubmission] = useState(null);
    const [googleDocComments, setGoogleDocComments] = useState(null);

    // Fetch selected history item
    useEffect(() => {
        if (!selectedItem) return;

        getHistoryItem(selectedItem.historyId, "ReportCardV2")
            .then((response) => {
                const rawSubmission = response.data;
                const formattedSubmission = {
                    essay: rawSubmission.initial_essay,
                    modelResponseJSON: rawSubmission.model_response_json,
                    // date: new Date(rawSubmission.created_at).toLocaleString(), not currently used
                    athenaModel: rawSubmission.athena_model,
                    supplementalEssayCategory:
                        rawSubmission.supplemental_essay_category,
                };
                setSubmission(formattedSubmission);
            })
            .catch((error) =>
                toast.error(
                    `Error fetching history item: ${error?.response?.data?.error}`
                )
            );
    }, [selectedItem]);

    useEffect(() => {
        if (submission?.modelResponseJSON) {
            setGoogleDocComments(
                <GoogleDocComments
                    modelResponseJSON={submission.modelResponseJSON}
                    reportCardV2ID={selectedItem.historyId}
                />
            );
        }
    }, [submission]);

    let athenaModel = "";
    switch (submission?.athenaModel) {
        case "flagship":
            athenaModel = "Flagship";
            break;
        case "pro":
            athenaModel = "Athena Pro";
            break;
        default:
            break;
    }

    return (
        <div className={styles.previous_submission_display_container}>
            <div className={styles.submission_header}>
                <div className={styles.submission_info}>
                    <div className={styles.submission_name}>
                        {selectedItem?.essayPreview}
                    </div>
                    <div className={styles.submission_timestamp}>
                        {selectedItem?.date}
                    </div>
                </div>
                <BaseButton
                    className={`${styles.button}`}
                    onClick={() => setShowPreviousSubmissionDisplay(false)}
                >
                    <img
                        className={styles.button_icon}
                        src={DoubleChevronRightIcon}
                        alt="button for closing athena sidebar"
                    />
                </BaseButton>
            </div>
            <div className={styles.submission_body}>
                <div className={styles.submission_essay_container}>
                    <div className={styles.submission_essay_title}>
                        Your Submission
                    </div>
                    <div>{submission?.essay}</div>
                </div>
                <div className={styles.submission_model_response_container}>
                    <div className={styles.submission_model_response_header}>
                        <div className={styles.submission_model_response_title}>
                            Athena's feedback
                        </div>
                        <div
                            className={
                                styles.submission_model_response_athena_model
                            }
                        >
                            {athenaModel + " Model"}
                        </div>
                    </div>
                    <div className={styles.model_response_container}>
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                        >
                            {FormatModelResponse(
                                submission?.modelResponseJSON?.grading_breakdown
                            )}
                        </ReactMarkdown>
                    </div>
                    <div className={styles.divider} />
                    {googleDocComments}
                    <div className={styles.divider} />
                    <div className={styles.essay_feedback_content}>
                        <h1>In-Depth Review</h1>
                        <div className={styles.model_response_container}>
                            <ReactMarkdown>
                                {FormatModelResponse(
                                    submission?.modelResponseJSON?.main_feedback
                                )}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function EssayReviewV2(props) {
    const [showSubmission, setShowSubmission] = useState(false);
    const [showV2PreviousSubmissions, setShowV2PreviousSubmissions] =
        useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const {
        essaySubmission,
        v2GradeErrorMessage,
        v2PreviousSubmissions,
        showV2PreviousSubmissionDisplay,
        setShowV2PreviousSubmissionDisplay,
    } = useApplicationContext();
    const errorMessageRef = useRef(null);

    // Slightly jank way to close the previous submission display when the sidebar tab is switched
    // TODO (Eugene): Fix entire state management flow
    useEffect(() => {
        setShowV2PreviousSubmissionDisplay(false);
    }, [setShowV2PreviousSubmissionDisplay]);

    useEffect(() => {
        if (v2GradeErrorMessage && errorMessageRef.current) {
            setTimeout(() => {
                errorMessageRef?.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500); // delay scroll to error to avoid interfering with sidebar opening animation
        }
    }, [v2GradeErrorMessage, errorMessageRef]); // We might not need this because it should already be in view?

    const handleShowSubmission = () => {
        if (!essaySubmission) return;
        setShowSubmission(!showSubmission);
    };

    return (
        <div className={styles.athena_essay_reviews}>
            <div className={styles.essay_submission}>
                <div
                    className={styles.essay_submission_header}
                    onClick={handleShowSubmission}
                >
                    <span className={styles.sidebar_section_title}>
                        Your Submission
                    </span>
                    <img
                        className={`${styles.chevron} ${
                            showSubmission && styles.rotated_chevron
                        }`}
                        src={ChevronDownIcon}
                        alt="chevron"
                    />
                </div>
                {/* TODO (Eugene): Make sure this essay submission can display markdown */}
                {showSubmission && essaySubmission && (
                    <div className={styles.essay_submission_content}>
                        {essaySubmission}
                    </div>
                )}
            </div>
            <EssayFeedback errorMessageRef={errorMessageRef} />
            <div className={styles.previous_submissions}>
                <div
                    className={styles.previous_submissions_header}
                    onClick={() =>
                        setShowV2PreviousSubmissions(!showV2PreviousSubmissions)
                    }
                >
                    <span className={styles.sidebar_section_title}>
                        Previous Submissions
                    </span>
                    <img
                        className={`${styles.chevron} ${
                            showV2PreviousSubmissions && styles.rotated_chevron
                        }`}
                        src={ChevronUpIcon}
                        alt="chevron"
                    />
                </div>
                {showV2PreviousSubmissions &&
                    (v2PreviousSubmissions.length > 0 ? (
                        <ul className={styles.previous_submissions_list}>
                            {v2PreviousSubmissions.map((item, index) => (
                                <PreviousSubmissionItem
                                    key={index}
                                    item={item}
                                    setSelectedItem={setSelectedItem}
                                    setShowPreviousSubmissionDisplay={
                                        setShowV2PreviousSubmissionDisplay
                                    }
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.no_previous_submissions}>
                            No previous submissions
                        </div>
                    ))}
            </div>
            {showV2PreviousSubmissionDisplay && (
                <V2PreviousSubmissionDisplay
                    selectedItem={selectedItem}
                    setShowPreviousSubmissionDisplay={
                        setShowV2PreviousSubmissionDisplay
                    }
                />
            )}
        </div>
    );
}
