import React, { useState } from 'react';
import ReactMarkdown from "react-markdown";
import { FormatModelResponse } from '../main/utils/utils';

import styles from "../../styles/auth/Testing2.module.css";

export default function Testing2() {
  const [inputText, setInputText] = useState('');
  const [formattedText, setFormattedText] = useState(null);

  const handleSubmit = () => {
    setFormattedText((inputText));
  };
  console.log("formattedText", inputText);
//   const fakeText = "# lmao\n\naite"

  return (
    <div>
      <h2>Markdown Formatter</h2>
      <textarea
        placeholder="Paste your markdown text here..."
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        rows="10"
        cols="50"
        style={{ width: '100%', marginBottom: '10px' }}
      />
      <button onClick={handleSubmit}>Submit</button>
      
      {formattedText && (
        <div className={styles.model_response_container}>
          <h3>Formatted Output</h3>
          <ReactMarkdown>{inputText.replace(/\\n/g, '\n')}</ReactMarkdown>
        </div>
      )}
    </div>
  );
}