import { verifyEmail } from "../../api/apiCalls";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "../../styles/auth/EmailVerification.module.css";
import BaseButton from "../base/BaseButton";
import { toast } from "react-toastify";

export default function EmailVerification() {
    const [searchParams] = useSearchParams();
    const userId = searchParams.get("user_id");
    const token = searchParams.get("token");

    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        const formData = {
            user_id: userId,
            token: token,
        };
        verifyEmail(formData)
            .then((response) => {
                toast.success("Email verified!");
                setVerified(true);
            })
            .catch((error) => {
                toast.error(`Error verifying email: ${error?.response?.data?.error}`);
                setVerified(false);
            });
        setLoading(false);
    }, [userId, token]);

    const navigate = useNavigate();

    const body = (
        <div>
            <h1>Email Verified!</h1>
            <p>Your email has been verified. You can now use your account to login.</p>
            <BaseButton className={styles.button} onClick={() => navigate("/")}>
                Get Started
            </BaseButton>
        </div>
    );

    return loading ? <div>Loading...</div> : body;
}
