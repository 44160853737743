import styles from "../../styles/auth/AuthPage.module.css";
import whiteAthenaV2 from "../../imgs/white_athena_v2.png";
import blackAthenaV2 from "../../imgs/black-athena-v2.png";
import BaseButton from "../base/BaseButton";
import useAuthContext from "./useAuthContext";
import { ReactComponent as CleverLogo } from "../../imgs/clever-blue-logomark.svg";

import { GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function LoginDivider() {
    return (
        <div className={styles.login_divider_container}>
            <div className={styles.login_horizontal_divider} />
            <span className={styles.login_horizontal_divider_text}>or</span>
            <div className={styles.login_horizontal_divider} />
        </div>
    );
}

export default function LoginPage(props) {
    const { handleGoogleLogin, handleUserLogin } = useAuthContext();
    const navigate = useNavigate();
    const [loginErrors, setLoginErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            username: e.target.email.value,
            password: e.target.password.value,
        };
        handleUserLogin(formData, props.navigateOnLoginCompletion).catch((error) =>
            setLoginErrors({ ...error?.response?.data?.error })
        );
    };
    const redirectUri =
        process.env.REACT_APP_IS_LOCAL_ENVIRONMENT === "True"
            ? process.env.REACT_APP_LOCAL_CLEVER_OAUTH_URL_ESCAPED
            : process.env.REACT_APP_PROD_CLEVER_OAUTH_URL_ESCAPED;
    const clientId = process.env.REACT_APP_CLEVER_APP_CLIENT_ID;
    const handleCleverLogin = () => {
        window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}`;
    };

    return (
        <div className={styles.login_body}>
            <div className={styles.auth_page_header}>
                <img src={blackAthenaV2} alt="Black Athena logo" onClick={() => navigate("/")} />
                <h1>Log into Athena</h1>
            </div>
            <div className={styles.login_form_inputs}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.form_input}>
                        <label htmlFor="email">Email Address</label>
                        <input type="email" id="email" name="email" placeholder="example@athenaco.ai" />
                        {loginErrors.email && <div className={styles.error}>{loginErrors.email}</div>}
                    </div>
                    <div className={styles.form_input}>
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" name="password" placeholder="Password" required />
                        {loginErrors.password && <div className={styles.error}>{loginErrors.password}</div>}
                    </div>
                    {loginErrors.non_field_errors && <div className={styles.error}>{loginErrors.non_field_errors}</div>}
                    <BaseButton className={styles.auth_action_button}>Log In</BaseButton>
                </form>
            </div>
            <LoginDivider />
            <div className={styles.login_method_container}>
                <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        handleGoogleLogin(credentialResponse, props.navigateOnLoginCompletion);
                    }}
                    onError={() => toast.error("Login failed")}
                    shape={"square"}
                    text={"continue_with"}
                    logo_alignment={"left"}
                    width={"360"}
                />
            </div>
            <div className={styles.login_method_container}>
                <BaseButton className={styles.auth_button} onClick={() => handleCleverLogin()}>
                    <CleverLogo className={styles.auth_button_icon} />
                    <span className={styles.auth_button_text}>Continue with Clever</span>
                </BaseButton>
            </div>
            <div className={styles.login_footer}>
                <Link to="/forgot-password" className={styles.link}>
                    Forgot your password?
                </Link>
                <span className={styles.link} onClick={props.onSignupClick}>
                    Create Account
                </span>
            </div>
        </div>
    );
}
