import styles from "../../styles/application/MyApplications.module.css";
import CollegeImg from "../../imgs/harvard_12.jpeg";
import Caltech from "../../imgs/caltech.png";
import MIT from "../../imgs/mit.png";
import Harvard from "../../imgs/harvard.png";
import { default as Dots } from "../../imgs/more-horizontal-icon.png";
import EditSquareIcon from "../../imgs/edit-square-icon.png";
import DeleteIcon from "../../imgs/delete-icon.png";
import AddSchoolIcon from "../../imgs/add-school-icon.png";
import closeIcon from "../../imgs/close-icon.png";
import EmptyDashboardImg from "../../imgs/empty-dashboard.png";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseButton from "../base/BaseButton";
import Dropdown from "../base/Dropdown";
import {
    dropdownSchoolList,
    dropdownSchoolListSearchTermMapping,
    schoolInfo,
    schoolToAdmissionPlans,
} from "./schoolList.js";
import { fetchAllApplications, createApplication, updateApplication, deleteApplication } from "../../api/apiCalls.js";
import { toast } from "react-toastify";
import useAuthContext from "../auth/useAuthContext";
import { ReactComponent as TrophyIcon } from "../../imgs/trophy-icon.svg";
import { Tooltip, TooltipContent, TooltipTrigger } from "../base/Tooltip.js";

import Banner from "../banner/Banner";
import useUserContext from "../auth/useUserContext";

// Takes in an application object and returns a formatted object with the necessary information for ApplicationCard
function formatApplicationData(application) {
    // TODO (Eugene): Fetch progress and total essays from backend?
    const schoolCode = application.school_code;
    const schoolName = schoolInfo[schoolCode]?.schoolName || schoolCode;
    const assets = schoolInfo[schoolCode]?.assets || {
        bannerImage: CollegeImg,
        logo: Caltech,
    };
    // TODO (Eugene): Let's make this less hacky
    const dueDate = schoolToAdmissionPlans[schoolCode]?.filter(
        (plan) => plan.metadata.admissionPlan === application.admission_plan
    )[0]?.metadata.dueDate;

    return {
        admissionPlan: application.admission_plan,
        dueDate: dueDate,
        applicationId: application.id,
        assets: assets,
        schoolCode: schoolCode,
        schoolName: schoolName,
        hasProModel: application.has_pro_model,
    };
}

function ApplicationCard(props) {
    const { application, onClick, setSelectedApplication, setShowEditSchoolModal, setShowDeleteWarningModal } = props;
    const [showMenu, setShowMenu] = useState(false);

    const formattedAdmissionPlan = application?.admissionPlan
        ?.split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    // Close the menu when clicking outside of it
    const menuRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const optionsMenuOnClick = (event) => {
        event.stopPropagation();
        setShowMenu(!showMenu);
    };

    // Options menu actions
    const handleEditClick = () => {
        setSelectedApplication(application);
        setShowEditSchoolModal(true);
        setShowMenu(false);
    };
    const handleDeleteClick = () => {
        setSelectedApplication(application);
        setShowDeleteWarningModal(true);
        setShowMenu(false);
    };

    return (
        <div className={styles.card} onClick={onClick}>
            {application?.hasProModel && (
                <div className={styles.pro_model_tag_container}>
                    <Tooltip>
                        <TooltipTrigger className={styles.icon_button}>
                            <TrophyIcon className={styles.trophy_icon} />
                        </TooltipTrigger>
                        <TooltipContent>Pro model available</TooltipContent>
                    </Tooltip>
                </div>
            )}
            <div className={styles.card_banner}>
                <img src={application?.assets?.bannerImage} alt="College image" />
            </div>
            <div className={styles.floating_logo}>
                <img src={application?.assets?.logo} alt="College logo" />
            </div>
            <div className={styles.card_body}>
                <h2 className={styles.card_title}>{application?.schoolName}</h2>
                {/* TODO (Eugene): Implement essays progress bar feature
                <p>
                    {application?.progress} / {application?.totalEssays} Essays Completed
                </p> */}
                <div>
                    <div>{formattedAdmissionPlan}</div>
                    <div>{application?.dueDate}</div>
                </div>
                <div
                    className={styles.action_menu_container}
                    ref={menuRef}
                    onClick={(event) => event.stopPropagation()}
                >
                    <img
                        className={styles.action_menu_dots}
                        src={Dots}
                        alt="see more horizontal dots icons"
                        onClick={optionsMenuOnClick}
                        role="button"
                    />
                    {showMenu && (
                        <div className={styles.action_menu}>
                            <div className={styles.menu_item} onClick={handleEditClick}>
                                <img className={styles.menu_icon} src={EditSquareIcon} alt="edit icon" />
                                Edit
                            </div>
                            <div className={styles.menu_item} onClick={handleDeleteClick}>
                                <img className={styles.menu_icon} src={DeleteIcon} alt="delete icon" />
                                <span className={styles.delete_text}>Delete</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function DeleteWarningModal(props) {
    const { setApplications, selectedApplication, setShowDeleteWarningModal } = props;
    const handleDeleteConfirmationClick = () => {
        deleteApplication(selectedApplication.applicationId)
            .then((response) => {
                setApplications((prevApplications) =>
                    prevApplications.filter((application) => application.schoolCode !== selectedApplication.schoolCode)
                );
            })
            .catch((error) => toast.error(error?.response?.data?.error));

        setShowDeleteWarningModal(false);
    };
    const schoolName = selectedApplication.schoolName;

    return (
        <div className={styles.modify_school_modal}>
            <div className={styles.modal_content}>
                <div className={styles.modal_header}>
                    <h2 className={styles.modal_title}>Delete Application</h2>
                    <BaseButton className={styles.close_button} onClick={() => setShowDeleteWarningModal(false)}>
                        <img src={closeIcon} alt="close modal" />
                    </BaseButton>
                </div>
                <p className={styles.modal_warning_text}>
                    Are you sure you want to delete your{" "}
                    <span className={styles.modal_warning_text_emphasis}>{schoolName}</span> application?
                </p>
                <p className={styles.modal_warning_text}>
                    This will remove the application from your dashboard, but you can re-add it later if needed.
                </p>
                <div className={styles.modal_footer}>
                    <BaseButton className={styles.cancel_button} onClick={() => setShowDeleteWarningModal(false)}>
                        Cancel
                    </BaseButton>
                    <BaseButton className={styles.modal_delete_school_button} onClick={handleDeleteConfirmationClick}>
                        Delete
                    </BaseButton>
                </div>
            </div>
            <div className={styles.gray_background} onClick={() => setShowDeleteWarningModal(false)}></div>
        </div>
    );
}

function EditSchoolModal(props) {
    const { setApplications, selectedApplication, setShowEditSchoolModal } = props;

    // We really should migrate to TypeScript...
    const schoolName = selectedApplication?.schoolName;
    const admissionPlanOptions = schoolToAdmissionPlans[selectedApplication?.schoolCode] || [];
    const prevAdmissionPlan = selectedApplication?.admissionPlan;
    const prevDropdownAdmissionPlan = admissionPlanOptions.find((option) => option.name === prevAdmissionPlan) || null;

    const [selectedAdmissionPlan, setSelectedAdmissionPlan] = useState(prevDropdownAdmissionPlan);

    // Dropdown states
    const [showAdmissionPlanDropdown, setShowAdmissionPlanDropdown] = useState(false);

    const handleEditSchoolClick = () => {
        const validateEditSchoolInput = (admissionPlan) => {
            if (admissionPlan?.name === null) {
                toast.warning("You need to select an admission plan!");
                return false;
            }
            return true;
        };
        if (validateEditSchoolInput(selectedAdmissionPlan)) {
            const formattedData = {
                admission_plan: selectedAdmissionPlan.metadata.admissionPlan,
            };
            updateApplication(selectedApplication.applicationId, formattedData)
                .then((response) => {
                    setApplications((prevApplications) =>
                        prevApplications.map((application) => {
                            if (application.schoolCode === selectedApplication.schoolCode) {
                                return {
                                    ...application,
                                    admissionPlan: selectedAdmissionPlan.name,
                                };
                            }
                            return application;
                        })
                    );
                })
                .catch((error) => toast.error(error?.response?.data?.error));
        }

        setShowEditSchoolModal(false);
    };

    const admissionPlanDropdownRef = useRef(null);
    const handleOutsideAdmissionPlanClick = (event) => {
        if (admissionPlanDropdownRef.current && !admissionPlanDropdownRef.current.contains(event.target)) {
            setShowAdmissionPlanDropdown(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideAdmissionPlanClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideAdmissionPlanClick);
        };
    }, []);

    const shouldDisableEditSchoolButton = selectedAdmissionPlan === null;

    return (
        <div className={styles.modify_school_modal}>
            <div className={styles.modal_content}>
                <div className={styles.modal_header}>
                    <h2 className={styles.modal_title}>Edit {schoolName} Application</h2>
                    <BaseButton className={styles.close_button} onClick={() => setShowEditSchoolModal(false)}>
                        <img src={closeIcon} alt="close modal" />
                    </BaseButton>
                </div>
                <div className={styles.dropdown_input_container}>
                    <div className={styles.dropdown_label}>Admission Plan</div>
                    <Dropdown
                        showDropdown={showAdmissionPlanDropdown}
                        setShowDropdown={setShowAdmissionPlanDropdown}
                        items={admissionPlanOptions}
                        selectedItem={selectedAdmissionPlan}
                        setSelectedItem={setSelectedAdmissionPlan}
                        placeholder={"Which admission plan are you applying for?"}
                        dropdownRef={admissionPlanDropdownRef}
                    />
                </div>
                <div className={styles.modal_footer}>
                    <BaseButton className={styles.cancel_button} onClick={() => setShowEditSchoolModal(false)}>
                        Cancel
                    </BaseButton>
                    <BaseButton
                        className={`${styles.modal_edit_school_button} ${
                            shouldDisableEditSchoolButton && styles.disabled_add_school_button
                        }`}
                        onClick={handleEditSchoolClick}
                        disabled={shouldDisableEditSchoolButton}
                    >
                        Edit
                    </BaseButton>
                </div>
            </div>
            <div className={styles.gray_background} onClick={() => setShowEditSchoolModal(false)}></div>
        </div>
    );
}

function AddSchoolModal(props) {
    const { applications, setApplications, setShowAddSchoolModal } = props;

    // Dropdown states
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedAdmissionPlan, setSelectedAdmissionPlan] = useState(null);

    const [showSchoolListDropdown, setShowSchoolListDropdown] = useState(false);
    const [showAdmissionPlanDropdown, setShowAdmissionPlanDropdown] = useState(false);

    const handleAddSchoolClick = () => {
        const validateAddSchoolInput = (school, admissionPlan) => {
            if (school?.metadata.schoolCode === null || admissionPlan?.name === null) {
                toast.warning("You need to select a school and an admission plan!");
                return false;
            }

            if (applications.find((application) => application.schoolCode === school.metadata.schoolCode)) {
                toast.warning("An application for this school already exists!");
                return false;
            }

            return true;
        };

        if (validateAddSchoolInput(selectedSchool, selectedAdmissionPlan)) {
            const formattedData = {
                school_code: selectedSchool.metadata.schoolCode,
                admission_plan: selectedAdmissionPlan.metadata.admissionPlan,
            };
            createApplication(formattedData)
                .then((response) => {
                    setApplications((prevApplications) => [
                        ...prevApplications,
                        formatApplicationData(response.data.application),
                    ]);
                })
                .catch((error) => toast.error(error?.response?.data?.error));
        }

        setShowAddSchoolModal(false);
    };

    const schoolListDropdownRef = useRef(null);
    const handleOutsideSchoolListClick = (event) => {
        if (schoolListDropdownRef.current && !schoolListDropdownRef.current.contains(event.target)) {
            setShowSchoolListDropdown(false);
        }
    };
    const admissionPlanDropdownRef = useRef(null);
    const handleOutsideAdmissionPlanClick = (event) => {
        if (admissionPlanDropdownRef.current && !admissionPlanDropdownRef.current.contains(event.target)) {
            setShowAdmissionPlanDropdown(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideSchoolListClick);
        document.addEventListener("mousedown", handleOutsideAdmissionPlanClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideSchoolListClick);
            document.removeEventListener("mousedown", handleOutsideAdmissionPlanClick);
        };
    }, []);

    const admissionPlanOptions = schoolToAdmissionPlans[selectedSchool?.metadata?.schoolCode] || [];
    const shouldDisableAddSchoolButton = selectedSchool === null || selectedAdmissionPlan === null;

    return (
        <div className={styles.modify_school_modal}>
            <div className={styles.modal_content}>
                <div className={styles.modal_header}>
                    <h2 className={styles.modal_title}>Add a New Application</h2>
                    <BaseButton className={styles.close_button} onClick={() => setShowAddSchoolModal(false)}>
                        <img src={closeIcon} alt="close modal" />
                    </BaseButton>
                </div>
                <div className={styles.dropdown_input_container}>
                    <div className={styles.dropdown_label}>School Name</div>
                    <Dropdown
                        showDropdown={showSchoolListDropdown}
                        setShowDropdown={setShowSchoolListDropdown}
                        items={dropdownSchoolList}
                        selectedItem={selectedSchool}
                        setSelectedItem={setSelectedSchool}
                        placeholder={"Select a school"}
                        dropdownRef={schoolListDropdownRef}
                        searchable={true}
                        searchTermMapping={dropdownSchoolListSearchTermMapping}
                    />
                </div>
                <div className={styles.dropdown_input_container}>
                    <div className={styles.dropdown_label}>Admission Plan</div>
                    <Dropdown
                        showDropdown={showAdmissionPlanDropdown}
                        setShowDropdown={setShowAdmissionPlanDropdown}
                        items={admissionPlanOptions}
                        selectedItem={selectedAdmissionPlan}
                        setSelectedItem={setSelectedAdmissionPlan}
                        placeholder={"Which admission plan are you applying for?"}
                        dropdownRef={admissionPlanDropdownRef}
                        disabled={selectedSchool === null}
                    />
                </div>
                <div className={styles.modal_footer}>
                    <BaseButton className={styles.cancel_button} onClick={() => setShowAddSchoolModal(false)}>
                        Cancel
                    </BaseButton>
                    <BaseButton
                        className={`${styles.modal_add_school_button} ${
                            shouldDisableAddSchoolButton && styles.disabled_add_school_button
                        }`}
                        onClick={handleAddSchoolClick}
                        disabled={shouldDisableAddSchoolButton}
                    >
                        Add School
                    </BaseButton>
                </div>
            </div>
            <div className={styles.gray_background} onClick={() => setShowAddSchoolModal(false)}></div>
        </div>
    );
}

function EmptyDashboard(props) {
    const { setShowAddSchoolModal } = props;
    return (
        <div className={styles.empty_dashboard_container}>
            <img src={EmptyDashboardImg} className={styles.empty_dashboard_image} alt="empty dashboard" />
            <div className={styles.empty_dashboard_content}>
                <h2 className={styles.empty_dashboard_title}>Ready to craft your future?</h2>
                <p className={styles.empty_dashboard_subtitle}>
                    The first step is always the hardest. Let's make it easy!
                </p>
                <BaseButton className={styles.empty_dashboard_button} onClick={() => setShowAddSchoolModal(true)}>
                    <img src={AddSchoolIcon} alt="add application icon" />
                    Add School
                </BaseButton>
            </div>
        </div>
    );
}

export default function MyApplications(props) {
    const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);
    const [showEditSchoolModal, setShowEditSchoolModal] = useState(false);
    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
    const [showSortDropdown, setShowSortDropdown] = useState(false);
    const sortOptions = [
        { name: "All" },
        {
            name: "Early",
            metadata: {
                admissionPlans: [
                    "early_action",
                    "early_action_1",
                    "early_action_2",
                    "early_action_3",
                    "early_action_4",
                    "early_decision",
                    "early_decision_1",
                    "early_decision_2",
                    "restrictive_early_action",
                    "restrictive_early_action_with_arts_portfolio",
                ],
            },
        },
        {
            name: "Regular",
            metadata: {
                admissionPlans: [
                    "regular_decision",
                    "rolling_admission",
                    "regular_decision_with_arts_portfolio",
                    // "uc_system",
                ],
            },
        },
    ];
    const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
    const [selectedApplication, setSelectedApplication] = useState(null);

    const navigate = useNavigate();

    // Stores all info related to each application, should replace applicationInfo
    const [applications, setApplications] = useState([]); // [{ schoolCode: "caltech", admissionPlan: "Regular Decision"}]
    const [areApplicationsLoading, setAreApplicationsLoading] = useState(true); // Replace with Suspense/more robust loading fallback
    const { consultantCurrentStudent } = useAuthContext();

    useEffect(() => {
        setAreApplicationsLoading(true);
        fetchAllApplications()
            .then((response) => {
                const rawApplications = response.data.applications;
                const formattedApplications = rawApplications.map((application) => formatApplicationData(application));
                setApplications(formattedApplications);
            })
            .catch((error) => toast.error(`Error fetching applications: ${error?.response?.data?.error}`))
            .finally(() => {
                setTimeout(() => {
                    setAreApplicationsLoading(false);
                }, 300);
            });
    }, [consultantCurrentStudent?.id]);

    // Sort dropdown outside click
    const dropdownRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowSortDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const filteredApplications = applications.filter((application) => {
        if (selectedSortOption.name === "All") return true;
        return selectedSortOption.metadata.admissionPlans.includes(application.admissionPlan);
    });

    const { isIEC } = useUserContext();

    return (
        <div className={styles.dashboard_container}>
            {!isIEC && <Banner />}
            <div className={styles.dashboard}>
                <div className={styles.dashboard_header}>
                    <h1 className={styles.dashboard_title}>My Applications</h1>
                    <BaseButton
                        className={styles.add_school_button}
                        onClick={() => setShowAddSchoolModal(!showAddSchoolModal)}
                    >
                        <img src={AddSchoolIcon} alt="add application icon" />
                        New Application
                    </BaseButton>
                </div>
                <div className={styles.dashboard_dropdown_container}>
                    <Dropdown
                        previewPrefix={"Sort by: "}
                        showDropdown={showSortDropdown}
                        setShowDropdown={setShowSortDropdown}
                        items={sortOptions}
                        selectedItem={selectedSortOption}
                        setSelectedItem={setSelectedSortOption}
                        dropdownRef={dropdownRef}
                    />
                </div>
                {areApplicationsLoading ? (
                    <div className={styles.loading_screen}>Loading...</div>
                ) : applications.length === 0 ? (
                    <EmptyDashboard setShowAddSchoolModal={setShowAddSchoolModal} />
                ) : (
                    <div className={styles.dashboard_content}>
                        {filteredApplications.length > 0 ? (
                            filteredApplications
                                .sort((a, b) => a.schoolName.localeCompare(b.schoolName))
                                .map((application) => {
                                    return (
                                        <ApplicationCard
                                            key={application.schoolCode}
                                            application={application}
                                            onClick={() => navigate(`/applications/${application.applicationId}`)}
                                            setSelectedApplication={setSelectedApplication}
                                            setShowDeleteWarningModal={setShowDeleteWarningModal}
                                            setShowEditSchoolModal={setShowEditSchoolModal}
                                        />
                                    );
                                })
                        ) : (
                            <div className={styles.empty_dashboard_container}>
                                <p className={styles.empty_dashboard_subtitle}>
                                    No applications match your search query!
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {showAddSchoolModal && (
                <AddSchoolModal
                    applications={applications}
                    setApplications={setApplications}
                    setShowAddSchoolModal={setShowAddSchoolModal}
                />
            )}
            {showEditSchoolModal && (
                <EditSchoolModal
                    applications={applications}
                    setApplications={setApplications}
                    selectedApplication={selectedApplication}
                    setShowEditSchoolModal={setShowEditSchoolModal}
                />
            )}
            {showDeleteWarningModal && (
                <DeleteWarningModal
                    setApplications={setApplications}
                    selectedApplication={selectedApplication}
                    setShowDeleteWarningModal={setShowDeleteWarningModal}
                />
            )}
        </div>
    );
}
