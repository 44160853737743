import { useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import { Logtail } from "@logtail/browser";

import { shouldShowReportCardV2 } from "../../api/apiCalls";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN);

/*
 * UserProvider is a fix to the provider refresh bug that happens when we reload the page.
 * This bug happens because our previous provider lived inside the BrowserRouter, which
 * refreshes whenever the page is hard reloaded.
 *
 * THIS IS THE HIGHEST-LEVEL PROVIDER (besides GoogleOAuthProvider) in the frontend.
 *
 * TODO (Eugene): NAMING NEEDS TO BE DONE BETTER--we have a UserProvider and AuthProvider
 * but Auth lowkey should be at the top level
 */
export default function UserProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isIEC, setIsIEC] = useState(false);
    const [isUserContextLoading, setIsUserContextLoading] = useState(true);

    /*
     * User Profiles
     *
     * We have two user profiles:
     * 1. userProfile: The profile of the logged-in user.
     * 2. frontendActiveProfile: The profile of the user who is taking actions on the frontend.
     *
     * The frontendActiveProfile is used when an account is taking actions on behalf of another user.
     * For example, consultants don't have membership access for their own accounts, but they can
     * register accounts on behalf of their students. In this case, the consultant's profile is the
     * userProfile and the student's profile is the frontendActiveProfile.
     *
     * For normal users, the userProfile and frontendActiveProfile are the same.
     */
    const [userProfile, setUserProfile] = useState({});
    const [frontendActiveProfile, setFrontendActiveProfile] = useState({});
    const [showReportCardV2, setShowReportCardV2] = useState(false);

    useEffect(() => {
        if (!isLoggedIn) return; /* Prevent forbidden API calls before logged in */

        shouldShowReportCardV2()
            .then((response) => {
                if (response.data["should_show_report_card_v2"]) {
                    setShowReportCardV2(true);
                }
            })
            .catch((error) => {
                logtail.error("Error in checking next steps feature flag: ", error);
            });
    }, [isLoggedIn]);

    return (
        <UserContext.Provider
            value={{
                // User profile state
                userProfile,
                setUserProfile,

                // Active profile state
                frontendActiveProfile,
                setFrontendActiveProfile,

                // Authentication state
                isLoggedIn,
                setIsLoggedIn,
                isIEC,
                setIsIEC,

                // User Context loading state
                isUserContextLoading,
                setIsUserContextLoading,

                // Feature flag
                showReportCardV2,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
