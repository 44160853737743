import styles from "../styles/App.module.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthProvider from "./auth/AuthProvider";
import UserProvider from "./auth/UserProvider";
import ApplicationProvider from "./application/ApplicationProvider";
import useAuthContext from "./auth/useAuthContext";
import useUserContext from "./auth/useUserContext";
import {
    createBrowserRouter,
    Navigate,
    Outlet,
    RouterProvider,
    useLocation,
    useLoaderData,
    useRouteError,
} from "react-router-dom";
import AdminB2BActions from "./auth/AdminB2BActions";
import AdminDashboard from "./auth/AdminDashboard";
import AuthPage from "./auth/AuthPage";
import PricingPage from "./pricing/PricingPage";
import Demo from "./nacacDemo/Demo";
import NacacDemo from "./nacacDemo/NacacDemo";
import EmailVerification from "./auth/EmailVerification";
import Testing from "./auth/Testing";
import Testing2 from "./auth/Testing2";
import UserSettings from "./admin/UserSettings";
import CleverOAuthPage from "./auth/CleverOAuthPage";
import BuyNowPage from "./auth/BuyNowPage";
import { ReactComponent as LoadingSpinner } from "../imgs/loading.svg";

// Athena V2
import ApplicantBackground from "./applicant/ApplicantBackground";
import { ApplicationInitializer } from "./application/Application";
import MyApplications from "./application/MyApplications";
import { PlaygroundV2 } from "./playground/Playground";
import CommonApp from "./application/CommonApp";
import EducationalResources from "./education/resources/EducationalResources";

// History View Page
import HistoryItemPage from "./main/history/HistoryItemPage";

// Consultant UI
import ConsultantSettingsPage from "./consultant/ConsultantSettingsPage";
import AppRedirectPage from "./auth/AppRedirectPage";

// Static Components
import SideBar from "./sidebar/Sidebar";

import { AccountSetup } from "./auth/AccountSetup";
import { SidebarProvider } from "./sidebar/SidebarContext";
import AthenaToastContainer from "./base/Toast";
import { useInactivityTimer } from "./auth/InactivityTimer";
import { useEffect } from "react";
import { Logtail } from "@logtail/browser";

// API Calls for data loaders
import { checkAuthentication, getUserProfile } from "../api/apiCalls";

const logtail = new Logtail(
    process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN
);

// ============================================================================
// Migrating over to RouterProvider to take advantage of data APIs
// ============================================================================

const routeConfigs = {
    redirect: { requiresIEC: false },
};

async function userAuthLoader() {
    const checkAuthenticationResponse = await checkAuthentication();
    const loggedIn = checkAuthenticationResponse.data.is_authenticated;
    if (!loggedIn) {
        return {
            loggedIn: false,
            isIEC: false,
        };
    }

    const getUserProfileResponse = await getUserProfile();
    const isIEC =
        getUserProfileResponse.data.user?.special_account_type === "consultant";
    return {
        loggedIn: loggedIn,
        isIEC: isIEC,
    };
}

const router = createBrowserRouter([
    {
        element: <AppLayout />,
        children: [
            // Auth routes
            {
                path: "/login",
                element: <AuthPage authView={"login"} />,
                loader: userAuthLoader,
            },
            {
                path: "/signup",
                element: <AuthPage authView={"signup"} />,
                loader: userAuthLoader,
            },
            {
                path: "/forgot-password",
                element: <AuthPage authView={"forgot-password"} />,
                loader: userAuthLoader,
            },
            {
                path: "/reset-password",
                element: <AuthPage authView={"reset-password"} />,
                loader: userAuthLoader,
            },
            {
                path: "/buy-now",
                element: <BuyNowPage />,
                loader: userAuthLoader,
            },

            // Generic Routes
            {
                path: "/redirect",
                element: (
                    <ProtectedRoute
                        element={<AppRedirectPage />}
                        routeConfig={routeConfigs.redirect}
                    />
                ),
                loader: userAuthLoader,
            },
            {
                path: "/demo",
                element: <ProtectedRoute element={<Demo />} />,
                loader: userAuthLoader,
            },
            {
                path: "/nacac-demo",
                element: <NacacDemo />,
                loader: userAuthLoader,
            },
            {
                path: "/",
                element: <ProtectedRoute element={<MyApplications />} />,
                loader: userAuthLoader,
            },
            {
                path: "/pricing",
                element: (
                    <ProtectedRoute
                        element={<PricingPage shouldOpenInNewTab={true} />}
                    />
                ),
                loader: userAuthLoader,
            },
            {
                path: "/account-setup",
                element: <ProtectedRoute element={<AccountSetup />} />,
                loader: userAuthLoader,
            },
            {
                path: "/email-verification",
                element: <EmailVerification />,
                loader: userAuthLoader,
            },
            {
                path: "/settings",
                element: <ProtectedRoute element={<UserSettings />} />,
                loader: userAuthLoader,
            },

            // Debug history
            {
                path: "/history/item/:id",
                element: <ProtectedRoute element={<HistoryItemPage />} />,
                loader: userAuthLoader,
            },

            // Admin Testing
            {
                path: "/kyle",
                element: <ProtectedRoute element={<Testing />} />,
                loader: userAuthLoader,
            },
            {
                path: "/audrey",
                element: <ProtectedRoute element={<Testing2 />} />,
                loader: userAuthLoader,
            },
            {
                path: "/eggs",
                element: <ProtectedRoute element={<AdminDashboard />} />,
                loader: userAuthLoader,
            },
            {
                path: "/eggs/b2b",
                element: <ProtectedRoute element={<AdminB2BActions />} />,
                loader: userAuthLoader,
            },

            // Athena V2
            {
                path: "/background",
                element: <ProtectedRoute element={<ApplicantBackground />} />,
                loader: userAuthLoader,
            },
            {
                path: "/applications",
                element: <ProtectedRoute element={<MyApplications />} />,
                loader: userAuthLoader,
            },
            {
                path: "/applications/:id",
                element: (
                    <ProtectedRoute
                        element={
                            <ApplicationProvider key={"college-application"}>
                                <ApplicationInitializer />
                            </ApplicationProvider>
                        }
                    />
                ),
                loader: userAuthLoader,
            },
            {
                path: "/common-app",
                element: (
                    <ProtectedRoute
                        element={
                            <ApplicationProvider key={"common-app"}>
                                <CommonApp />
                            </ApplicationProvider>
                        }
                    />
                ),
                loader: userAuthLoader,
            },
            {
                path: "/playground",
                element: (
                    <ProtectedRoute
                        element={
                            <ApplicationProvider key={"playground"}>
                                <PlaygroundV2 />
                            </ApplicationProvider>
                        }
                    />
                ),
                loader: userAuthLoader,
            },
            {
                path: "/educational-resources",
                element: <ProtectedRoute element={<EducationalResources />} />,
                loader: userAuthLoader,
            },

            // Clever Auth
            {
                path: "/oauth/clever",
                element: <CleverOAuthPage />,
                loader: userAuthLoader,
            },

            // Consultant UI
            {
                path: "/consultant/settings",
                element: (
                    <ProtectedRoute element={<ConsultantSettingsPage />} />
                ),
                loader: userAuthLoader,
            },

            // TODO (Eugene): In lieu of a 404 page, we just redirect to home @_@
            {
                path: "*",
                element: <Navigate to="/" />,
                loader: userAuthLoader,
            },
        ],
        errorElement: <RootBoundary />,
    },
]);

function NewRouter() {
    return <RouterProvider router={router} fallbackElement={<LoadingPage />} />;
}

export default function App() {
    const clientId = process.env.REACT_APP_GSI_CLIENT_ID;

    return (
        <div className={styles.App}>
            <GoogleOAuthProvider clientId={clientId}>
                <UserProvider>
                    <NewRouter />
                </UserProvider>
            </GoogleOAuthProvider>
        </div>
    );
}

function AppLayout() {
    return (
        <>
            <AuthProvider>
                <PageComponent />
            </AuthProvider>
            <AthenaToastContainer />
        </>
    );
}

function PageComponent() {
    const { reloadUserContext } = useAuthContext();
    const { isLoggedIn } = useUserContext();
    const location = useLocation();

    // Auto scroll to top for a new route. Annoying if your previous scroll position
    // persists when you navigate to the next page.
    window.scrollTo(0, 0);

    // Log user out after 60 minutes of inactivity. This is a security measure to prevent unauthorized access.
    // and was requested by LAUSD.
    useInactivityTimer();

    // Reload authentication information on every page load.
    useEffect(() => {
        // TODO (Eugene): This is what's causing the glitching. Temporarily fix by only running on non-auth paths.
        const nonAuthPaths = [
            "/login",
            "/signup",
            "/forgot-password",
            "/reset-password",
            "/buy-now",
        ];
        if (nonAuthPaths.includes(location.pathname)) {
            reloadUserContext();
        }
    }, [location.pathname, reloadUserContext]);

    const isInBuyNowFlow = location.pathname.startsWith("/buy-now");

    return (
        <SidebarProvider>
            {isLoggedIn ? (
                <div className={styles.main_container}>
                    {!isInBuyNowFlow && <SideBar />}
                    <div
                        className={`${styles.main_section_container_v2} ${
                            isInBuyNowFlow && styles.adjust_margin
                        }`}
                    >
                        <Outlet />
                    </div>
                </div>
            ) : (
                <div>
                    <Outlet />
                </div>
            )}
        </SidebarProvider>
    );
}

const DEFAULT_PROTECTED_ROUTE_CONFIG = {
    requiresIEC: true,
    requiresLoggedIn: true,
};

function LoadingPage() {
    return (
        <div className={styles.loading_page}>
            <LoadingSpinner className={styles.loading_spinner} />
        </div>
    );
}

// Routes that are only accessible if specific conditions are met.
function ProtectedRoute({ element, routeConfig = {} }) {
    const { isUserContextLoading } = useUserContext();

    const userAuthInfo = useLoaderData();

    // TODO (Eugene): Update how we loading spinner this
    if (isUserContextLoading) {
        return <LoadingPage />;
    }

    const config = { ...DEFAULT_PROTECTED_ROUTE_CONFIG, ...routeConfig };

    if (config.requiresLoggedIn && !userAuthInfo?.loggedIn) {
        return <AuthPage authView={"login"} />;
    }

    // Uncomment when we launch IEC product
    // if (config.requiresIEC && userAuthInfo.isIEC) {
    //     // Navigate to iec.athenaco.ai if the user is an IEC
    //     return <Navigate to="/redirect?to=iec" replace={true} />;
    // }

    return element;
}

function RootBoundary() {
    const error = useRouteError();

    useEffect(() => {
        logtail.warn(error?.response?.data?.detail || "Unknown error", {
            additionalInfo: {
                requestUrl: error?.response?.config?.url,
            },
        });
    }, [error]);

    return (
        <div className={styles.error_boundary_container}>
            <p className={styles.error_message}>
                Something went wrong on our end. Please try again later. If the
                problem persists, please contact support@athenaco.ai
            </p>
        </div>
    );
}
