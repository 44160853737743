// Banner.jsx
import React from "react";
import styles from "../../styles/banner/Banner.module.css"; // Import CSS module or stylesheet

const Banner = () => {
    return (
        <div className={styles.banner}>
            🎉 Our UC model is now free to use for all students. Learn more in
            "Settings" 🎉
        </div>
    );
};

export default Banner;
