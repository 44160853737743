import styles from "../../styles/playground/Playground.module.css";
import { useEffect, useRef, useState } from "react";
import { CUSTOM_PROMPT_TITLE_MAX_LENGTH_CHARS, PROMPT_MAX_LENGTH_CHARS } from "../main/utils/utils.js";
import BaseButton from "../base/BaseButton";
import { toast } from "react-toastify";
import { Tooltip, TooltipContent, TooltipTrigger } from "../base/Tooltip.js";

import PlaygroundIcon from "../../imgs/playground-white-icon.png";
import CloseIcon from "../../imgs/close-icon.png";
import InfoIcon from "../../imgs/info-icon.png";
import EditSquareIcon from "../../imgs/edit-square-icon.png";
import DeleteIcon from "../../imgs/delete-icon.png";
import Dots from "../../imgs/more-horizontal-icon.png";

import { createApplication, fetchPlaygroundApplication } from "../../api/apiCalls.js";
import useApplicationContext from "../application/useApplicationContext";
import { PlaygroundApplication } from "../application/Application.js";

function EmptyPlayground(props) {
    const { setShowAddCustomPromptModal } = props;
    return (
        <div className={styles.empty_playground_container}>
            <p className={styles.empty_playground_text}>It seems like you don't have any custom prompts yet.</p>
            <BaseButton className={styles.add_prompt_button} onClick={() => setShowAddCustomPromptModal(true)}>
                <img className={styles.add_prompt_icon} src={PlaygroundIcon} alt="playground icon" />
                Add Custom Prompt
            </BaseButton>
        </div>
    );
}

function AddCustomPromptModal(props) {
    const { setShowAddCustomPromptModal } = props;
    const { handleCreatePlaygroundEssay } = useApplicationContext();

    const handleAddPromptClick = (customPromptTitle, customPrompt, maxWordCount) => {
        const validateAddSchoolInput = (customPromptTitle, customPrompt, maxWordCount) => {
            if (!customPromptTitle || !customPrompt || !maxWordCount) {
                toast.error("Please fill out all fields.");
                return false;
            }
            return true;
        };
        if (!validateAddSchoolInput(customPromptTitle, customPrompt, maxWordCount)) {
            return;
        }
        handleCreatePlaygroundEssay(customPromptTitle, customPrompt, maxWordCount);
        setShowAddCustomPromptModal(false);
    };
    const handleCloseModalClick = () => {
        setShowAddCustomPromptModal(false);
    };

    return (
        <ModifyCustomPromptModal
            {...props}
            handleMainActionClick={handleAddPromptClick}
            handleCloseModalClick={handleCloseModalClick}
            mainActionText={"Add Prompt"}
            modalTitle={"Add Custom Prompt"}
        />
    );
}

function EditCustomPromptModal(props) {
    const { setShowEditCustomPromptModal, selectedCustomPrompt } = props;
    const { handleEditPlaygroundEssay } = useApplicationContext();

    const handleEditPromptClick = (customPromptTitle, customPrompt, maxWordCount) => {
        const validateEditSchoolInput = (customPromptTitle, customPrompt, maxWordCount) => {
            if (!customPromptTitle || !customPrompt || !maxWordCount) {
                toast.error("Please fill out all fields.");
                return false;
            }
            return true;
        };
        if (!validateEditSchoolInput(customPromptTitle, customPrompt, maxWordCount)) {
            return;
        }
        handleEditPlaygroundEssay(selectedCustomPrompt.id, customPromptTitle, customPrompt, maxWordCount);
        setShowEditCustomPromptModal(false);
    };
    const handleCloseModalClick = () => {
        setShowEditCustomPromptModal(false);
    };

    return (
        <ModifyCustomPromptModal
            {...props}
            handleMainActionClick={handleEditPromptClick}
            handleCloseModalClick={handleCloseModalClick}
            mainActionText={"Edit Prompt"}
            modalTitle={"Edit Custom Prompt"}
        />
    );
}

function ModifyCustomPromptModal(props) {
    const { selectedCustomPrompt, handleMainActionClick, handleCloseModalClick, mainActionText, modalTitle } = props;

    const [customPromptTitle, setCustomPromptTitle] = useState(selectedCustomPrompt?.title ?? "");
    const [customPrompt, setCustomPrompt] = useState(selectedCustomPrompt?.text ?? "");
    const [maxWordCount, setMaxWordCount] = useState(selectedCustomPrompt?.maxWordCount ?? "");

    const allFieldsFilled = customPromptTitle && customPrompt && maxWordCount;

    return (
        <div className={styles.add_custom_prompt_modal}>
            <div className={styles.modal_content}>
                <div className={styles.modal_header}>
                    <h2 className={styles.modal_title}>{modalTitle}</h2>
                    <BaseButton className={styles.close_button} onClick={handleCloseModalClick}>
                        <img className={styles.close_icon} src={CloseIcon} alt="Close Icon" />
                    </BaseButton>
                </div>
                <div className={styles.modal_body}>
                    <section className={styles.modal_input_section}>
                        <label className={styles.modal_input_label} htmlFor="customTitleTextarea">
                            Title
                        </label>
                        <input
                            className={styles.custom_prompt_title_input}
                            id={"customTitleTextarea"}
                            placeholder="Give your prompt a title (e.g. Scripps College Prompt 1)"
                            onChange={(event) => setCustomPromptTitle(event.target.value)}
                            value={customPromptTitle}
                            draggable={false}
                            maxLength={CUSTOM_PROMPT_TITLE_MAX_LENGTH_CHARS}
                        />
                    </section>
                    <section className={styles.modal_input_section}>
                        <label className={styles.modal_input_label} htmlFor="customPromptTextarea">
                            Prompt
                        </label>
                        <textarea
                            className={styles.modal_textarea}
                            id={"customPromptTextarea"}
                            placeholder={"Enter your custom prompt here"}
                            onChange={(event) => setCustomPrompt(event.target.value)}
                            value={customPrompt}
                            draggable={false}
                            maxLength={PROMPT_MAX_LENGTH_CHARS}
                        />
                    </section>
                    <section className={styles.modal_input_section}>
                        <label className={styles.modal_input_label} htmlFor="maxWordCountInput">
                            Maximum Word Count
                            <Tooltip>
                                <TooltipTrigger className={styles.modal_info_button}>
                                    <img src={InfoIcon} className={styles.help_icon} />
                                </TooltipTrigger>
                                <TooltipContent className={styles.modal_info_tooltip_container}>
                                    <p>We use this information to more appropriately grade your essay.</p>
                                    <p>
                                        If the prompt asks for a page or character limit, an estimated word count is
                                        acceptable.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </label>
                        <input
                            className={styles.max_word_count_input}
                            id="maxWordCountInput"
                            onChange={(event) => setMaxWordCount(event.target.value)}
                            value={maxWordCount}
                            placeholder="e.g. 200"
                            maxLength={5}
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                    </section>
                </div>
                <div className={styles.modal_footer}>
                    <BaseButton className={styles.cancel_button} onClick={handleCloseModalClick}>
                        Cancel
                    </BaseButton>
                    <BaseButton
                        className={`${styles.add_prompt_button} ${!allFieldsFilled && styles.disabled}`}
                        onClick={() => handleMainActionClick(customPromptTitle, customPrompt, maxWordCount)}
                        disabled={!allFieldsFilled}
                    >
                        {mainActionText}
                    </BaseButton>
                </div>
            </div>
            <div className={styles.gray_background} onClick={handleCloseModalClick}></div>
        </div>
    );
}

function DeleteWarningModal(props) {
    const { selectedCustomPrompt, setShowDeleteWarningModal } = props;
    const { handleDeletePlaygroundEssay } = useApplicationContext();

    const handleDeleteConfirmationClick = () => {
        handleDeletePlaygroundEssay(selectedCustomPrompt.id);
        setShowDeleteWarningModal(false);
    };
    const promptTitle = selectedCustomPrompt.title;

    return (
        <div className={styles.delete_school_modal}>
            <div className={styles.modal_content}>
                <div className={styles.modal_header}>
                    <h2 className={styles.modal_title}>Delete Custom Prompt</h2>
                    <BaseButton className={styles.close_button} onClick={() => setShowDeleteWarningModal(false)}>
                        <img src={CloseIcon} alt="close modal" />
                    </BaseButton>
                </div>
                <p className={styles.modal_warning_text}>
                    Are you sure you want to delete "
                    <span className={styles.modal_warning_text_emphasis}>{promptTitle}</span>"?
                </p>
                <p className={styles.modal_warning_text}>
                    This action cannot be undone. Deleting this prompt will remove all associated data.
                </p>
                <div className={styles.modal_footer}>
                    <BaseButton className={styles.cancel_button} onClick={() => setShowDeleteWarningModal(false)}>
                        Cancel
                    </BaseButton>
                    <BaseButton className={styles.modal_delete_school_button} onClick={handleDeleteConfirmationClick}>
                        Delete
                    </BaseButton>
                </div>
            </div>
            <div className={styles.gray_background} onClick={() => setShowDeleteWarningModal(false)}></div>
        </div>
    );
}

function PlaygroundPromptCard(props) {
    const { prompt, setSelectedCustomPrompt, setShowDeleteWarningModal, setShowEditCustomPromptModal, setShowEditor } =
        props;
    const { setSelectedPrompt } = useApplicationContext();

    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    // Options menu actions
    const optionsMenuOnClick = (event) => {
        event.stopPropagation();
        setShowMenu(!showMenu);
    };
    const handleEditClick = () => {
        setSelectedCustomPrompt(prompt);
        setShowEditCustomPromptModal(true);
        setShowMenu(false);
    };
    const handleDeleteClick = () => {
        setSelectedCustomPrompt(prompt);
        setShowDeleteWarningModal(true);
        setShowMenu(false);
    };

    const handleCardClick = () => {
        setShowEditor(true);
        setSelectedPrompt(prompt);
    };

    return (
        <div className={styles.playground_prompt_card} onClick={handleCardClick}>
            <div className={styles.card_title}>{prompt.title}</div>
            <div className={styles.card_text}>{prompt.text}</div>
            <div className={styles.action_menu_container} ref={menuRef} onClick={(event) => event.stopPropagation()}>
                <img
                    className={styles.action_menu_dots}
                    src={Dots}
                    alt="see more horizontal dots icons"
                    onClick={optionsMenuOnClick}
                    role="button"
                />
                {showMenu && (
                    <div className={styles.action_menu}>
                        <div className={styles.menu_item} onClick={handleEditClick}>
                            <img className={styles.menu_icon} src={EditSquareIcon} alt="edit icon" />
                            Edit
                        </div>
                        <div className={styles.menu_item} onClick={handleDeleteClick}>
                            <img className={styles.menu_icon} src={DeleteIcon} alt="delete icon" />
                            <span className={styles.delete_text}>Delete</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function PlaygroundPromptsList(props) {
    const {
        prompts,
        setShowAddCustomPromptModal,
        setShowEditCustomPromptModal,
        setShowDeleteWarningModal,
        setSelectedCustomPrompt,
        setShowEditor,
    } = props;

    return (
        <ul className={styles.playground_prompt_list}>
            {prompts
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((prompt, index) => (
                    <PlaygroundPromptCard
                        key={index}
                        prompt={prompt}
                        setSelectedCustomPrompt={setSelectedCustomPrompt}
                        setShowAddCustomPromptModal={setShowAddCustomPromptModal}
                        setShowEditCustomPromptModal={setShowEditCustomPromptModal}
                        setShowDeleteWarningModal={setShowDeleteWarningModal}
                        setShowEditor={setShowEditor}
                    />
                ))}
        </ul>
    );
}

export function PlaygroundV2(props) {
    const [showAddCustomPromptModal, setShowAddCustomPromptModal] = useState(false);
    const [showEditCustomPromptModal, setShowEditCustomPromptModal] = useState(false);
    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);

    // For editing custom prompts
    const [selectedCustomPrompt, setSelectedCustomPrompt] = useState(null);
    const { prompts, isPlaygroundLoading, handleInitializePlayground } = useApplicationContext();
    const [showEditor, setShowEditor] = useState(false);

    // Clears prompts + sets application ID for Application Context on initial load
    useEffect(() => {
        handleInitializePlayground();
    }, []);

    return showEditor ? (
        <PlaygroundApplication setShowEditor={setShowEditor} />
    ) : (
        <div className={styles.playground_container_v2}>
            <div className={styles.playground_description}>
                <div className={styles.playground_header}>
                    <h1 className={styles.playground_title}>Playground</h1>
                    <BaseButton className={styles.add_prompt_button} onClick={() => setShowAddCustomPromptModal(true)}>
                        <img className={styles.add_prompt_icon} src={PlaygroundIcon} alt="playground icon" />
                        Add Custom Prompt
                    </BaseButton>
                </div>
                <p className={styles.playground_text}>
                    If we do not support your desired university and you still want feedback on your supplemental
                    essays, this playground is for you!
                </p>
            </div>
            <div className={styles.playground_content}>
                {isPlaygroundLoading ? (
                    <div>Loading...</div>
                ) : prompts.length === 0 ? (
                    <EmptyPlayground setShowAddCustomPromptModal={setShowAddCustomPromptModal} />
                ) : (
                    <PlaygroundPromptsList
                        prompts={prompts}
                        setSelectedCustomPrompt={setSelectedCustomPrompt}
                        setShowAddCustomPromptModal={setShowAddCustomPromptModal}
                        setShowEditCustomPromptModal={setShowEditCustomPromptModal}
                        setShowDeleteWarningModal={setShowDeleteWarningModal}
                        setShowEditor={setShowEditor}
                    />
                )}
            </div>
            {showAddCustomPromptModal && (
                <AddCustomPromptModal setShowAddCustomPromptModal={setShowAddCustomPromptModal} />
            )}
            {showEditCustomPromptModal && (
                <EditCustomPromptModal
                    setShowEditCustomPromptModal={setShowEditCustomPromptModal}
                    selectedCustomPrompt={selectedCustomPrompt}
                />
            )}
            {showDeleteWarningModal && (
                <DeleteWarningModal
                    selectedCustomPrompt={selectedCustomPrompt}
                    setShowDeleteWarningModal={setShowDeleteWarningModal}
                />
            )}
        </div>
    );
}
