import styles from "../../styles/auth/AuthPage.module.css";
import useAuthContext from "./useAuthContext";
import whiteAthenaV2 from "../../imgs/white_athena_v2.png";
import blackAthenaV2 from "../../imgs/black-athena-v2.png";
import BaseButton from "../base/BaseButton";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ForgotPasswordPage() {
    const navigate = useNavigate();
    const { handleSendPasswordResetEmail } = useAuthContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [forgotPasswordError, setForgotPasswordError] = useState("");
    const [sentEmail, setSentEmail] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            email: e.target.email.value,
        };
        setIsSubmitting(true);

        handleSendPasswordResetEmail(formData)
            .then((_) => {
                setIsSubmitting(false);
                setSentEmail(true);
            })
            .catch((error) => {
                setForgotPasswordError(error?.response?.data?.error);
                setIsSubmitting(false);
            });
    };

    const body = sentEmail ? (
        <div className={styles.forgot_password_sent_email_text}>
            <p>
                We're on it! If the email you entered matches an account in our records, you'll receive a password reset
                email shortly.
            </p>
            <p>Don't forget to check your spam or junk mail folders.</p>
        </div>
    ) : (
        <div className={styles.forgot_password_form_inputs}>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" name="email" placeholder="example@athenaco.ai" />
                {forgotPasswordError && <div className={styles.error}>{forgotPasswordError}</div>}
                <BaseButton className={styles.auth_action_button} disabled={isSubmitting}>
                    Send Email
                </BaseButton>
            </form>
        </div>
    );
    return (
        <div className={styles.forgot_password_body}>
            <div className={styles.auth_page_header}>
                <img src={blackAthenaV2} alt="Black Athena logo" onClick={() => navigate("/")} />
                <h1>Forgot Password</h1>
                <p>Enter the email associated with your account and we will send you an email to reset the password.</p>
            </div>
            {body}
            <div className={styles.forgot_password_footer}>
                <Link to="/login" className={styles.link}>
                    Back to Log In
                </Link>
            </div>
        </div>
    );
}
